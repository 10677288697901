import React, { FC, Component, useCallback, useEffect, useState } from "react"
import unescapeJs from "unescape-js"
import $ from "jquery";
import {reactLocalStorage} from "reactjs-localstorage";
import {
  createStyles,
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Card,
  Container,
  CopyButton,
  Divider,
  Drawer,
  Flex,
  Global,
  Grid,
  Group,
  Header,
  Input,
  Loader,
  MantineProvider,
  Menu,
  Modal,
  NumberInput,
  Overlay,
  Stack,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ModalsProvider } from "@mantine/modals";
import {
  randomId,
  useDisclosure,
  useWindowScroll,
  useForceUpdate,
  useTimeout,
} from "@mantine/hooks";
import {
  IconClipboardCheck,
  IconCopy,
  IconAlertTriangle,
  IconExclamationCircle,
  IconMap,
  IconMapSearch,
  IconRefresh,
  IconSearch,
  IconWorldLatitude,
  IconWorldLongitude,
  IconZoomInArea,
} from "@tabler/icons";
import { HeaderMenu } from "../";
import { Map } from "../";
import { ActionsGrid } from "../";

// const light = "../data/fonts/open-sans-v34-latin-300.woff2";
// const regular = "../data/fonts/open-sans-v34-latin-regular.woff2";
// const bold = "../data/fonts/open-sans-v34-latin-600.woff2";

// Styles
import "leaflet/dist/leaflet.js"
import "leaflet/dist/leaflet.css"
import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";
import "font-awesome/css/font-awesome.min.css";
import "../../App.css"

// Leaflet
import L from "leaflet";
import map from "leaflet";
import {
  LayersControl,
  MapContainer,
  Marker,
  GeoJSON,
  Pane,
  Popup,
  TileLayer,
  WMSTileLayer,
  // useMap,
  useMapEvents,
} from "react-leaflet";
import { Geocoder, geocoders } from "leaflet-control-geocoder";

import axios from "axios";

// import MarkerClusterGroup from "react-leaflet-markercluster";
// import GeoJsonCluster from "react-leaflet-geojson-cluster";
// L.Marker.prototype.options.icon = L.icon({
//   iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png"
// });

// import * as pizzageojson from "../pizzaGeoJson.json";
// import { PizzaMarkers } from "../PizzaMarkers";
// console.log(typeof sensors);


// import icon from "leaflet/dist/images/marker-icon.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";
// let DefaultIcon = L.icon({
//     iconUrl: icon,
//     shadowUrl: iconShadow
// });
// L.Marker.prototype.options.icon = DefaultIcon;

// Components
// import ClusterWithSuperCluster from "..//Map/ClusterWithSuperCluster"
// import RenderMarkerSuperCluster from "..//Map/RenderMarkerSuperCluster"

/**
 * Taken from ecovvillages.org
 * @see https://ecovillage.org/projects/map/
 */
// import locations from "../../data/json/worldwide_ecovvillages.json";
// var location = GeoJSONCluster("../../data/worldwide_ecovvillages.json");
// console.info(locations);
// const data: GeoJSON.FeatureCollection = locations;
// console.info(locations.features);


import {
  GenerateHash,
  ReGenerateHash,
  GetHash,
  CleanHash4URL,
  GetMapSourceAddress,
  GetNameHash,
  GetRandomHash,
  DelayedSearch,
} from "../_utilities/hash";
import map_layers from "../../data/json/map_layers.json";


const useStyles = createStyles((theme) => ({
  search_input: {
    position: "absolute",
    bottom: "28px",
    width: "70%",
    left: "15%",
    maxWidth: "unset !important"
  },
  inner: {
    position: "fixed",
    height: "100%",
    width: "100%",
    backgroundColor: "transparent !important",
    padding: 0
  },
  coordinate: {
    fontFamily: "monospace !important",
    fontSize: "0.9rem",
    backgroundColor: "#333",
    borderRadius: "3px",
    padding: "2px 4px",
    width: "calc(50% - (300px / 2))"
  },
  zoom: {
    fontFamily: "monospace !important",
    fontSize: "0.9rem",
    backgroundColor: "#333",
    borderRadius: "3px",
    padding: "2px 4px !important",
    maxWidth: "75px",

    input: {
      paddingLeft: "5px",
      textAlign: "right",
    }
  },
  street: {
    margin: 0,
  },
  MapSourceAddress: {
    width: "100%",

    input: {
      fontFamily: "monospace",
      paddingLeft: "100px",
      cursor: "auto",
    },
  },
  temp_marker: {},
}));

// Default coordinates
const coords_lat_default = 41.87194;
const coords_lng_default = 12.56738;
const hash = GenerateHash();

/**
 * Main App
 */
export default function Main() {
  const { classes } = useStyles();
  const [scroll, scrollTo] = useWindowScroll();
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [zoom, setZoom] = useState<number>(12);
  const [position, setPosition] = useState(null);
  const [addressLoaderVisible, setaddressLoaderVisible] = useState(true);

  const [statusErrorIcon, setStatusErrorIcon] = useState("");
  const [statusErrorMsg, setStatusErrorMsg] = useState("");
  const [statusErrorColor, setStatusErrorColor] = useState("dark");

  const [urlLoaderVisible, setUrlLoaderVisible] = useState(false);
  const drawerClass = ((!drawerOpened) ? "closed" : "opened");
  const [street, setStreet] = useState("");
  const [address, setAddress] = useState("");
  const theme = useMantineTheme();

  const has_coords = (Object.keys(reactLocalStorage.getObject("coords")).length > 0),
        coords = (has_coords) ? Object.values(reactLocalStorage.getObject("coords")) : [coords_lat_default, coords_lng_default],
        coords_lat = coords[0],
        coords_lng = coords[1];
                           // https://www.openstreetmap.org/query?lat=41.81802&lon=12.71527#map=18/41.81797/12.71622&layers=H
  const openstreetmap_link = "https://www.openstreetmap.org/query?lat=" + coords_lat + "&lon=" + coords_lng + "&mlat=" + coords_lat + "&mlon=" + coords_lng + "#map=" + zoom + "/" + coords_lat + "/" + coords_lng + "&layers=HN";
  const opencyclemap_link = "https://www.opencyclemap.org/?zoom=" + zoom + "&lat=" + coords_lat + "&lon=" + coords_lng + "&layers=B0000/";

  // const coords = Object.values(reactLocalStorage.getObject("coords"));
  // const coords_readable = (has_coords) ? reactLocalStorage.getObject("coords").toString() : [coords_lat_default, coords_lng_default].toString()
  const temp_marker_layer_name = "<b>Coordinate selezionate</b><br />(<tt><small>" + coords_lat + "</small></tt>, <tt><small>" + coords_lng + "</small></tt>)";
  const mapsource_address = GetMapSourceAddress();
  const mapsource_address_val:string = window.location as unknown as string;
  const layerControls = map_layers.map((data, index) => (
    <div key={data.id}>
      <LayersControl.BaseLayer name={data.name}>
        <TileLayer
          attribution={data.attribution}
          url={data.url}
        />
      </LayersControl.BaseLayer>
    </div>
  ));
  const getAddress = async (lat:any, lng:any, zoom:number): Promise<string> => {
    console.info("Getting info for these coordinates: ", lat, lng, zoom, "...");

    setaddressLoaderVisible(true);
    // Set timer to 20 seconds
    // setTimeout(() => {
    //   setStatusErrorIcon("IconAlertTriangle");
    //   setStatusErrorMsg("Problema di rete");
    //   setStatusErrorColor("yellow");
    //
    //   // Set timer to 50 seconds
    //   setTimeout(() => {
    //     // setStatusErrorIcon("IconExclamationCircle");
    //     setStatusErrorMsg("Problema ad Internet");
    //     setStatusErrorColor("red");
    //   }, 50000);
    // }, 20000);

    const { data } = await axios
      .get("https://nominatim.openstreetmap.org/reverse", {
        params: {
          "lat": lat,
          "lon": + lng,
          "format": "json",
          "zoom": zoom,
          "accept-language": "it",
          // "$limit": 500,
          // "$$app_token": YOUR_APP_TOKEN
        }
      }
    ).then(response => {
      // console.warn(response.data.display_name);
      setStreet(response.data.display_name);
      setaddressLoaderVisible(false);

      return response.data.display_name;
    })
    .catch(error => {
      // $("#street_loader").fadeIn();
      console.error(error)
    });
    return data;
  };
  const flyToIn = function name(map:any, lat:any, lng:any, zoom:number) {
    map.flyTo([lat, lng], map.getZoom());
  }
  const flyToOut = function name(map:any, lat:any, lng:any, zoom:number) {
    map.flyTo([lat, lng], map.getZoom());
  }
  const form = useForm({
    initialValues: {
      // name: "",
      // email: "",
    },
  });

  const SelectAPoint = (e:any) => {
    const [changedCoords, setChangedCoords] = useState({latitude: 0, longitude: 0});
    const pos = "";
    const map = useMapEvents({
      click(e) {
        // console.log(e);

        // Ask for current position
        // map.locate();

        const lat = e.latlng.lat,
              lng = e.latlng.lng,
              latlng = [lat, lng],
              zoom = map.getZoom();

        // console.log(lng, lng);
        reactLocalStorage.setObject("coords", latlng);
        reactLocalStorage.set("zoom", zoom);
        setDrawerOpened(true);
        // console.warn(map);

        map.setView([lat, lng], zoom);

        getAddress(lat, lng, zoom+2);
      },
      locationfound(e) {
        console.log(e);

        // setPosition(e.latlng.lat, e.latlng.lng)
        map.flyTo(e.latlng, map.getZoom());
      },

      // locationfound(location) {
      //   console.info("location found:", location);
      //   // setPosition(e.latlng)
      //   // map.flyTo(e.latlng, map.getZoom())
      // }
    });

    if(map) {
      const zoom = map.getZoom();
      setZoom(zoom);
      reactLocalStorage.set("zoom", zoom);
    }

    if(has_coords) {
      return (
        <div>
          <Marker
            position={[coords_lat, coords_lng]}
            icon={L.icon({
              iconUrl: "marker-icon-temp.png",
              iconSize: [40, 40],
              iconAnchor: [18, 36],
            })}
            eventHandlers={{
              click: () => {
                // console.log("Re-clicked on custom marker");
                setDrawerOpened(true);

                /**
                 * Change the cursor type to loader
                 * NOTE: Must to be tested because there"s no connection now
                 */
                // $("body").css({"cursor": "wait"});
                // setTimeout(() => {
                //   if($("#map_modal")) {
                //     $("body").css({"cursor": "initial"});
                //   }
                // }, 1000);
              },
            }}
            title="Marker selezionato"
            >
          </Marker>
        </div>
      );
    } else {
      return (<></>);
    }
  }

  // function onEachFeature(feature: any, layer: L.Layer) {
  //   if (feature.properties) {
  //     const { popupContent } = feature.properties;
  //     layer.bindPopup(popupContent);
  //   }
  // }
  // function Show5gLayer() {
  //   var map = $("#map");
  //   if (map.getZoom() >= 9) {
  //     if (!map.hasLayer(pointsLayer)) map.addLayer(pointsLayer);
  //   } else if (map.hasLayer(pointsLayer)) {
  //     map.removeLayer(pointsLayer);
  //   }
  // }
  $(document).ready(() => {
    $("#marker_name").on("keyup", () => {
      setUrlLoaderVisible(false);
      var from = {property: 0};
      var to = {property: 100};

      $(from).animate(to, {
        duration: 100,
        step: function() {
          console.log( "Currently @ " + this.property );
        }
      });
    });
    // $("#map").on("zoomend", () => {
    //   if(zoom >= 9) {
    //     Show5gLayer();
    //   }
    // });
  });

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontFamily: "Open Sans",
        colorScheme: "dark",
        colors: {
          // deepBlue: ["#E9EDFC", "#C1CCF6", "#99ABF0" /* ... */],
          // blue: ["#E9EDFC", "#C1CCF6", "#99ABF0" /* ... */],
        },
        loader: "dots"
      }}
    >
      <Container className={classes.inner} fluid>
        <HeaderMenu />

        <Container className={classes.search_input}>
          <Input
            id="search_input"
            icon={<IconSearch />}
            variant="filled"
            placeholder="Cerca una via, una località, un #ecovillaggio, #coliving, #wwoofing, #progetti, #collaborazioni..."
            size="xl"
            className="search_input"
            defaultValue={street}
          />
        </Container>
        <Modal
          opened={drawerOpened}
          onClose={() => {
            setDrawerOpened(false);
            // flyToOut(map, lat, lng, zoom);
          }}
          title="Coordinate selezionate"
          padding="lg"
          size="80%"
          id="map_modal"
          closeOnEscape={true}
          closeOnClickOutside={true}
          lockScroll={false}
        >
          <Card.Section mt={5} px={0}>
            <Grid grow p={0}>
              <Grid.Col span={10} p={5}>
                <Flex
                  justify="flex-start"
                  align="flex-end"
                  direction="row"
                  wrap="wrap"
                >
                  <TextInput className={classes.coordinate} label="Latitudine" defaultValue={coords_lat} icon={<IconWorldLatitude size={16} />} readOnly={true} />
                  <TextInput className={classes.coordinate} label="Longitudine" defaultValue={coords_lng} icon={<IconWorldLongitude size={16} />} readOnly={true} />
                  <NumberInput className={classes.zoom} label="Zoom" defaultValue={zoom} readOnly={true} />
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Stack
                  align="flex-end"
                  justify="flex-end"
                  spacing={5}
                  mt={10}
                >
                  <Tooltip
                    label="Vedi questa visuale su OpenStreetMap"
                    position="left"
                    color="gray"
                    withArrow
                  >
                    <Button
                      variant="light"
                      color="cyan"
                      compact
                      component="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={openstreetmap_link}
                      size="xs"
                      leftIcon={<IconMapSearch size={14} />}
                      pt={3}
                    >
                      Vedi su OpenStreetMap
                    </Button>
                  </Tooltip>
                  <Tooltip
                    label="Vedi questa visuale su OpenCycleMap"
                    position="left"
                    color="gray"
                    withArrow
                  >
                    <Button
                      variant="light"
                      color="cyan"
                      compact
                      component="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={opencyclemap_link}
                      size="xs"
                      leftIcon={<IconMap size={14} />}
                      pt={3}
                    >
                      Vedi su OpenCycleMap
                    </Button>
                  </Tooltip>
                </Stack>
              </Grid.Col>
            </Grid>
          </Card.Section>
          <Card.Section mt={0} px={0}>
            <Group grow position="left" sx={{ justifyContent: "safe !important", alignItems: "flex-start !important" }}>
              <div id="calculated_address">
                <Tooltip label="Per raffinare la precisione aumenta il livello di zoom" withArrow position="top-start" color="gray.7">
                  <TextInput
                    type="text"
                    className={classes.street}
                    color={statusErrorColor}
                    label="Indirizzo individuato da questa quota (livello di zoom):"
                    value={street}
                    rightSection={addressLoaderVisible && <Loader variant="oval" color="#1971c2" size="xs" />}
                    px={0}
                  />
                </Tooltip>
              </div>
              <div id="status_messages">
                <br />
                <UnstyledButton>
                  <IconAlertTriangle color={statusErrorColor} size={18} />
                  <Text ml={10} color={statusErrorColor} component="small" size={15}>{statusErrorMsg}</Text>
                </UnstyledButton>
              </div>
            </Group>
          </Card.Section>

          <Card.Section mt={30} mb={30}>
            <Text component="span" fz="md" className="short_url_text">
            URL generato per questo luogo:
            </Text>
            <Group style={{padding: 0}} position="apart" id="mapsource_input_area">
              <Input
                icon={
                  <Badge color="red" variant="outline" id="mapsource_badge">
                    DINAMICO
                  </Badge>
                }
                fz="xl"
                my="0"
                size="md"
                pointer={true}
                id="mapsource_address"
                className={classes.MapSourceAddress}
                value={mapsource_address}
                rightSection={urlLoaderVisible && <Loader variant="oval" color="#1971c2" size="xs" /> || <Tooltip label="Rigenera" position="right" withArrow>
                    <ActionIcon sx={{ display: "inline", verticalAlign: "-1px", padding: "2px" }} color="grey" onClick={() => {
                      if(reactLocalStorage.get("selected_marker") == "static") {
                        var marker_name:string = $("#marker_name").val() as string;
                        const hash = (marker_name.length > 0) ? marker_name : "";
                        const urlhash = CleanHash4URL(hash);
                        const mapsource_address = GetMapSourceAddress(urlhash);
                        reactLocalStorage.set("hash", urlhash);
                        reactLocalStorage.set("selected_marker", "static");
                        history.pushState("", "", mapsource_address);
                        $("#mapsource_address").css("padding-left", "105px").val(mapsource_address);
                        $("#mapsource_badge").css({"color": "#22b8cf", "border-color": "#22b8cf"});
                        $("#mapsource_badge .mantine-Badge-inner").text("STATICO");
                        $("#mapsource_address").val(mapsource_address);
                        $("#marker_name").focus();
                      } else if(reactLocalStorage.get("selected_marker") == "dynamic") {
                        const hash = GenerateHash();
                        const urlhash = CleanHash4URL(hash);
                        const mapsource_address = GetMapSourceAddress(urlhash);
                        history.pushState("", "", mapsource_address);
                        reactLocalStorage.set("hash", urlhash);
                        reactLocalStorage.set("selected_marker", "dynamic");
                        $("#mapsource_address").val(mapsource_address);
                      } else {
                        reactLocalStorage.set("hash", "");
                      }
                    }}>
                      <IconRefresh size={20} />
                    </ActionIcon>
                  </Tooltip>
                }
              />
              <CopyButton value={mapsource_address_val} timeout={2000}>
                {({ copied, copy }) => (
                  <Tooltip label={copied ? "Link copiato!" : "Clicca per copiare"} withArrow position="right">
                    <ActionIcon ml={5} sx={{ display: "inline", verticalAlign: "-1px", padding: "2px" }} color={copied ? "teal" : "grey"} onClick={copy}>
                      {copied ? <IconClipboardCheck size={20} /> : <IconCopy size={20} />}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Group>
          </Card.Section>

          <Divider variant="dashed" mt={40} mb={10} />

          <Group
            position="apart"
            grow
            mt={10}
            sx={{ padding: "0 !important" }}
          >
            <ActionsGrid urlLoaderVisible={urlLoaderVisible} setAddress={setAddress} />
          </Group>
        </Modal>

        <MapContainer
          center={[coords_lat_default, coords_lng_default]}
          zoom={6}
          maxZoom={18}
          minZoom={3}
          zoomControl={true}
          doubleClickZoom={false}
          scrollWheelZoom={true}
          dragging={true}
          easeLinearity={0.35}
          id="map"
        >
          <LayersControl position={"bottomright"}>
            {layerControls}
            <LayersControl.BaseLayer checked name="Humanitarian (default)">
              <TileLayer
                attribution="donn&eacute;es &copy; <a href=\"//osm.org/copyright\">OpenStreetMap</a>/ODbL - Tiles courtesy of <a href=\"https://hot.openstreetmap.org/\">Humanitarian OpenStreetMap Team</a>"
                url="http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>

            <LayersControl.Overlay name="Copertura 5G">
              <TileLayer
                attribution=" and <a href=\"//www.nperf.com\" target=\"_blank\">nPerf</a>"
                url="https://app.nperf.com/nr-map-{z}-{x}-{y}.png"
              />
            </LayersControl.Overlay>

            <LayersControl.Overlay checked name="Worldwide Ecovvillages">
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name={temp_marker_layer_name}>
              <SelectAPoint />
            </LayersControl.Overlay>
          </LayersControl>
        </MapContainer>
      </Container>
    </MantineProvider>
  );
}
