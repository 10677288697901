import { useState } from "react";
import {
  createStyles,
  Divider,
  Group,
  MediaQuery,
  Navbar,
  Text,
  Tooltip,
  useMantineColorScheme
} from "@mantine/core";
import {
  IconChevronRight,
  IconFileText,
  IconNotebook,
  IconPrinter,
} from "@tabler/icons";
import $ from "jquery";
import { reactLocalStorage } from "reactjs-localstorage";
import JsPDF from 'jspdf';

const FOOTER_CSS_WITH = "calc(100% - 415px)";
const useStyles = createStyles((theme, { opened }: { opened: boolean }, getRef) => ({
  navbar: {
    borderRight: "0px none",
    background: theme.colorScheme === "dark" ? "unset" : "linear-gradient(to left, #eee, #fcfcfc 8px)",
    boxShadow: theme.colorScheme === "dark" ? "0 0 100px #111 inset" : "0 0 100px #fff inset",
  },

  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: theme.spacing.md * 1.5,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  panelIcon: {
    transition: "transform 150ms ease",
    transform: (opened) ? "rotate(-180deg)" : "rotate(0deg)",
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      // [`& .${icon}`]: {
      //   color: theme.colorScheme === "dark" ? theme.white : theme.black,
      // },
    },
  },

  linkIcon: {
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor }).color,
      // [`& .${icon}`]: {
      //   color: theme.fn.variant({ variant: "light", color: theme.primaryColor }).color,
      // },
    },
  },
}));

// const data = [
//   { link: "", label: "opened", icon: IconVocabulary },
//   { link: "", label: "Notifications", icon: IconBellRinging },
//   { link: "", label: "Billing", icon: IconReceipt2 },
//   { link: "", label: "Security", icon: IconFingerprint },
//   { link: "", label: "SSH Keys", icon: IconKey },
//   { link: "", label: "Databases", icon: IconDatabaseImport },
//   { link: "", label: "Authentication", icon: Icon2fa },
//   { link: "", label: "Other Settings", icon: IconSettings },
// ];
const data:any = [];
const table_of_contents_txt:string = "Tabella dei contenuti";
const print:string = "Stampa";
const save_in_pdf:string = "Salva in pdf";
const save_in_epub:string = "Salva in epub";

// if(reactLocalStorage.get("panel_opened") == undefined) {
//   reactLocalStorage.set("panel_opened", true);
// }
const default_panel_opened = eval(reactLocalStorage.get("panel_opened"));

export function LeftSidebar() {
  const [opened, setOpened] = useState(default_panel_opened);
  const { classes, cx } = useStyles({ opened });
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [active, setActive] = useState("Billing");
  const [iconText, setIconText] = useState(default_panel_opened ? table_of_contents_txt : "");

  const open_panel:string = "Apri il pannello";
  const close_panel:string = "Chiudi il pannello";
  const openClosePanel:string = (opened) ? close_panel : open_panel;

  const links = data.map((item:any) => (
    <a
      className={cx(classes.link, { [classes.linkActive]: item.label === active })}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));
  const generatePDF = () => {
    const report = new JsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: 16 // or "smart", default is 16
    });
    // report.html($('#pdf').text(), {
    //   callback: function(doc) {
    //     doc.save();
    //   },
    //   x: 10,
    //   y: 10
    //   // {
    //   //   renderingMode: "fill",
    //   //   align: "left",
    //   //   maxWidth: 190
    //   // }
    // });
    // report.text(
    //   $('#pdf').text(),
    //   10,
    //   10,
    //   {
    //     renderingMode: "fill",
    //     align: "left",
    //     maxWidth: 190
    //   }
    // );
    // report.save('report.pdf');
    // console.log(report);

    // report.html($('#pdf').html()).then(() => {
    //   report.save('report.pdf');
    // });
    report.html($('#pdf').html()).then(() => {
      report.save('report.pdf');
    });
  };


  return (
    <MediaQuery smallerThan="md" styles={{ display: "none !important" }}>
      <Group>
        <Navbar id="sidebar" width={{ sm: (opened) ? 225 : 76 }} px={15} py={15} className={classes.navbar}>
          <Navbar.Section grow>
            <Group className={classes.header} position="apart">
              <Tooltip
                label={openClosePanel}
                color="gray"
                position="bottom"
                withArrow
              >
                <Text
                  component="a"
                  href="#"
                  px={12}
                  py={0}
                  c="dimmed"
                  onClick={(event) => {
                    // reactLocalStorage.set("panel_opened", !opened);
                    event.preventDefault();
                    (opened) ? setOpened(false) : setOpened(true);
                    (opened) ? setIconText("") : setIconText(table_of_contents_txt);
                    $("#logo_shadow").animate({"margin-left": ((opened) ? 45 : 195)}, 100);
                    $("#logo_img").animate({"margin-left": ((opened) ? 10 : 160)}, 100);
                    $("#footer_menu, #subfooter").css({
                      "margin-left": (opened) ? 60 : "auto",
                      "margin-right": (opened) ? "calc(100% - 215px)" : "auto",
                      "width": (opened) ? "auto" : "100%",
                      "minWidth": (opened) ? "calc(100% - 250px)" : FOOTER_CSS_WITH
                    });
                    $("#contents").css({"width": ((opened) ? "calc(100% - 77px)" : "calc(100% - 226px)")});
                  }}
                >
                  <IconChevronRight size={16} stroke={1.5} className={classes.panelIcon} /> <Text mt={2} component="span">{iconText}</Text>
                </Text>
              </Tooltip>
            </Group>
            {links}
          </Navbar.Section>

          <Navbar.Section className={classes.footer}>
            <Tooltip
              label={print}
              key={print}
              color="gray"
              position="right"
              withArrow
            >
              <Text
                component="a"
                href="#"
                className={classes.link}
                px={12}
                onClick={(event) => { window.print(); event.preventDefault(); }}
              >
                <IconPrinter size={16} stroke={1.5} /> {opened ? print : ""}
              </Text>
            </Tooltip>
            <Tooltip
              label={save_in_pdf}
              key={save_in_pdf}
              color="gray"
              position="right"
              withArrow
            >
              <Text
                component="a"
                href="#"
                className={classes.link}
                px={12}
                onClick={(event) => {
                  generatePDF();
                  event.preventDefault();
                }}
              >
                <IconFileText size={16} stroke={1.5} /> {opened ? save_in_pdf : ""}
              </Text>
            </Tooltip>
            <Tooltip
              label={save_in_epub}
              key={save_in_epub}
              color="gray"
              position="right"
              withArrow
            >
              <Text
                component="a"
                href="#"
                className={classes.link}
                onClick={(event) => event.preventDefault()}
                px={12}
              >
                <IconNotebook size={16} stroke={1.5} /> {opened ? save_in_epub : ""}
              </Text>
            </Tooltip>
          </Navbar.Section>
        </Navbar>
        <Divider
          orientation="vertical"
          ml={-16}
          w={1}
          sx={{
            border: "0px none",
            background: colorScheme === "dark" ? "linear-gradient(to bottom, #ff00005d, #333, #0000ff3d)" : "linear-gradient(to bottom, #ff00005d, #eee, #0000ff3d)"
          }} />
      </Group>
    </MediaQuery>
  );
}
