import { createElement } from "react";
import { transpile } from "typescript";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  createStyles,
  ActionIcon,
  Anchor,
  Box,
  Container,
  Divider,
  Group,
  Image,
  MediaQuery,
  Stack,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineColorScheme,
} from "@mantine/core";
import {
  IconHeart,
  IconPizza,
  IconBrandFacebook,
  IconBrandInstagram
} from "@tabler/icons";
import { SiMewe } from "react-icons/si";
import { GiFrog } from "react-icons/gi";
import { GetLanguage, SetLanguage, } from "../_utilities/languages";

import footer_menu from "../../data/json/footer_menu.json";
// import footer_socials from "../../data/json/footer_socials.json";
const footer_socials = [
  {
    type: "icon",
    name: "Facebook",
    color: "blue",
    hoverColor: "#228be6",
    icon: IconBrandFacebook,
    link: "https://www.facebook.com/groups/sharingprojectscommunity.ecovillaggi.cohousing",
  },
  {
    type: "icon",
    name: "MeWe",
    color: "cyan",
    hoverColor: "teal",
    icon: SiMewe,
    link: "https://mewe.com/join/ecovillaggicamerecolivingwwofingcooworking"
  },
  {
    type: "icon",
    name: "Gab",
    color: "green",
    hoverColor: "green",
    icon: GiFrog,
    link: "https://gab.com/groups/18818"
  },
  {
    type: "image",
    name: "Freedom Cell Network",
    color: "orange.7",
    hoverColor: "orange",
    icon: "freedom-cells_small_50.png",
    link: "https://freedomcells.org/cells/sharing-projects-community/"
  }
];

const language = GetLanguage();
const language_id = language.id;
const links = footer_menu[language_id as keyof typeof footer_menu];

const MENU_HEIGHT = 69;
const LOGO_WIDTH = (MENU_HEIGHT * 1.5);
const LOGO_HEIGHT = (MENU_HEIGHT * 1.5);
const PADDING_TOP = (MENU_HEIGHT/1.5-1);
const FOOTER_CSS_WITH = "calc(100% - 415px)";


const useStyles = createStyles((theme, _params, getRef) => {
  const panel_opened = eval(reactLocalStorage.get("panel_opened"));
  const icon = getRef("icon");
  return {
    footer: {
      marginTop: 0,
      background: theme.colorScheme === "dark" ? "linear-gradient(to top right, #232323 50%, #111)" : "linear-gradient(to top right, #fcfcfc 50%, #ccc)",
      boxShadow: theme.colorScheme === "dark" ? "0 0 9px #232323 inset" : "0 0 9px #bbb inset",
      borderTop: `1px solid ${
        theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
    },

    logo: {
      maxWidth: 200,

      // [theme.fn.smallerThan("sm")]: {
      //   display: "flex",
      //   flexDirection: "column",
      //   alignItems: "center",
      // },
    },

    logo_img: {
      // rotate: "45deg",
      // marginTop: "0 !important",
      // marginRight: "0 !important",
      // marginBottom: "0 !important",
      // marginLeft: "-45px !important",
    },

    onlyPrint: {
      display: "none",
    },

    description: {
      marginTop: 5,

      // [theme.fn.smallerThan("sm")]: {
      //   marginTop: theme.spacing.xs,
      //   textAlign: "center",
      // },
    },

    footer_menu: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 0,

      // [theme.fn.largerThan("xs")]: {
        marginLeft: (panel_opened) ? "auto" : 60,
        marginRight: (panel_opened) ? "auto" : "calc(100% - 215px)",
        width: (panel_opened) ? "100%" : "auto",
        minWidth: (panel_opened) ? FOOTER_CSS_WITH : "calc(100% - 250px)",
        // flexDirection: "column",
        // alignItems: "center",
      // },
    },

    groupsContainer: {
      [theme.fn.smallerThan("md")]: {
        display: "none",
      },
    },

    groups: {
      display: "flex",
      flexWrap: "wrap",
    },

    wrapper: {
      minWidth: 120,
      maxWidth: 180,
      margin: "0 15px",
    },

    link: {
      display: "block",
      color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[6],
      fontSize: theme.fontSizes.sm,
      paddingTop: 3,
      paddingBottom: 3,

      "&:hover": {
        textDecoration: "underline",
      },
    },

    title: {
      fontSize: theme.fontSizes.md,
      fontWeight: 700,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      marginBottom: theme.spacing.xs / 2,
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },

    subfooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing.xl,
      paddingTop: theme.spacing.xl,
      paddingRight: 0,
      paddingBottom: theme.spacing.xl,
      paddingLeft: 0,
      borderTop: `1px solid ${
        theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,

      [theme.fn.largerThan("sm")]: {
        marginLeft: (panel_opened) ? "auto" : 60,
        marginRight: (panel_opened) ? "auto" : "calc(100% - 215px)",
        width: (panel_opened) ? "100%" : "auto",
        minWidth: (panel_opened) ? FOOTER_CSS_WITH : "calc(100% - 250px)"
      },
      [theme.fn.smallerThan("sm")]: {
        // margin: "20px auto",
        paddingTop: 10,
        alignContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        marginLeft: 75,
        marginRight: 75,
      },
    },

    socials: {
      [theme.fn.smallerThan("sm")]: {
        // opacity: "0.6",
        // float: "left",
        // alignContent: "start !important",
        // marginTop: "-2mm",
        // marginLeft: "-2.5mm",
        // zoom: "0.9",
        // marginTop: theme.spacing.xs,
      },
    },
    madeby: {
      // marginBottom: "-5px",
    },
    sharing: {
      marginTop: "2px",

      "&:hover": {
        color: "red",
      }
    },
  };
});
let maps = {}


export function Footer() {
  const { classes } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const panel_opened = eval(reactLocalStorage.get("panel_opened"));

  const groups = links.map((group) => {
    const links = group.links.map((link, index) => (
      <Text<"a">
        key={link.label}
        className={classes.link}
        component="a"
        href={link.link}
        onClick={(event) => {
          if(link.active == "false") {
            event.preventDefault();
          }
        }}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  const socials = footer_socials.map((item:any) => {
    if (typeof item.icon !== "undefined") {
      const Icon = createElement(item.icon, false, false);
      if(item.type == "icon") {
        return (
          <Tooltip
            label={item.name}
            key={item.name}
            color={item.color}
            position="top-end"
            withArrow
          >
            <ActionIcon component="a" key={item.name} href={item.link} target="_blank" size="lg" color={(colorScheme === "dark") ? "gray.6" : "gray"} sx={{ "&:hover": { color: item.hoverColor }}}>
              <item.icon size={18} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        );
      } else {
        const icon = item.icon;
        return (
          <Tooltip
            label={item.name}
            key={item.name}
            color={item.color}
            position="top-end"
            withArrow
          >
            <ActionIcon component="a" title={item.name} key={item.name} href={item.link} target="_blank" size="lg">
              <Image src={icon} width={16} />
            </ActionIcon>
          </Tooltip>
        );
      }
    }
  });

  return (
    <footer id="footer" className={classes.footer}>
      <Divider mt={-1} h={1} sx={{
        border: "0px none",
        background: colorScheme === "dark" ? "linear-gradient(to right, #0000ff6d, transparent)" : "linear-gradient(to right, #0000ff3d, transparent)"
      }} />
      <img id="footer_logo_print" className={classes.onlyPrint} width={120} height={60} src="logo_negative_horizontal.png" />
      <Container
        id="footer_menu"
        className={classes.footer_menu}
        pt={40}
      >
        <div id="footer_logo" className={classes.logo}>
          <Image
            width={100}
            className={classes.logo_img}
            ml={-55}
            mb={50}
            src={(colorScheme === "dark") ? "logo_horizontal.png" : "logo_negative_horizontal.png"}
          />
          <Group>
            <UnstyledButton
              component="a"
              target="_blank"
              href="https://sharing-projects.community"
              title="Sharing Projects Community"
            >
              <Image
                width={120}
                className={classes.logo_img}
                ml={-7}
                src="logo sharing projects community-txt_coloured__250.png"
              />
            </UnstyledButton>
            <Group spacing={0}>
              <Text
                className={classes.description}
                color={(colorScheme === "dark") ? "dark.2" : "gray.7"}
                sx={{ display: "flex", minWidth: "70px" }}
                ta="left"
                fz={11}
                fw={700}
              >
                Made in Italy
              </Text>
              <Text
                className={classes.description}
                color={(colorScheme === "dark") ? "gray.7" : "gray.6"}
                ta="left"
                sx={{ display: "flex", minWidth: "200px" }}
                fz={11}
                fw={700}
              >
                with <IconHeart size={14} className={classes.madeby} color="red" /> and <IconPizza size={14} className={classes.madeby} color="orange" />
              </Text>
            </Group>
          </Group>
        </div>
        <Stack className={classes.groupsContainer}>
          <div className={classes.groups}>
            {groups}
          </div>
          <br />
          <Text color="red.9" size="xs" align="right">Per favore, staccati dallo schermo quanto prima!</Text>
        </Stack>
      </Container>
      <Container
        id="subfooter"
        className={classes.subfooter}
        p={0}
        pb={25}
        mt={10}
        mb={-25}
      >
        <Text color="dimmed" size="xs">
          © 2023 <Anchor color={(colorScheme === "dark") ? "dark.0" : "gray.7"} className={classes.sharing} fz="xs" target="_blank" href="https://sharing-projects.community">Sharing Projects Community</Anchor>. All rights reserved.
        </Text>
        <Tooltip
          label="Ricorda di non passare troppo tempo sui Social"
          color="#a04848"
          position="left"
          withArrow
        >
          <Group id="socials" spacing={0} className={classes.socials} position="right" noWrap>
            <Text size={10} color="dimmed" mt={4}>Segui le nostre communities:</Text> {socials}
          </Group>
        </Tooltip>
      </Container>
    </footer>
  );
}
