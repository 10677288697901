import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import {
  createStyles,
  Button,
  Center,
  Container,
  Divider,
  Drawer,
  Group,
  Header,
  Image,
  MediaQuery,
  Menu,
  Space,
  Stack,
  Text,
  TextInput,
  useMantineColorScheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconSearch } from "@tabler/icons";

import { GetLanguage, SetLanguage, } from "../_utilities/languages";
import { GoToTop } from "../GoToTop/GoToTop";
import { MobileMenu } from "../HeaderMenuDocs/MobileMenu";
import { NavigationFeatures } from "../HeaderMenuDocs/NavigationFeatures";

import layer_type from "../../data/json/map_layers.json";
import menu from "../../data/json/menu.json";

const language = GetLanguage();
const language_id = language.id;
const links = menu[language_id as keyof typeof menu];

const MENU_HEIGHT = 50;
const LOGO_WIDTH = (69 * 1.5);
const LOGO_HEIGHT = (69 * 1.5);
const PADDING_TOP = (MENU_HEIGHT/1.5-1);

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  inner: {
    height: 120,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "bottom",
    top: 0,
  },

  container: {
    background: theme.colorScheme === "dark" ? "linear-gradient(to bottom, #222, #111 85%)" : "linear-gradient(to bottom, #fff 85%, #fafafa)",
    height: "100%",
    padding: 10,
    maxWidth: "unset"
  },

  logoImg: {
    top: 0,
    left: 0,
    position: "absolute",
    rotate: "90deg",
  },

  logoShadow: {
    height: 1,
    width: 62,
    top: 120,
    position: "absolute",
    border: "0px none",
    background: "linear-gradient(to left, transparent, #ff00005d, transparent)",
    zIndex: 1000000000,
  },

  logoPrint: {
    display: "none",
  },

  logoUrl: {
    display: "none",
  },

  menu_docs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none !important",
    },
  },

  link: {
    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    display: "block",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    lineHeight: 1,
    paddingBottom: "10px",
    paddingLeft: "35px",
    paddingRight: "10px",
    paddingTop: PADDING_TOP,
    textDecoration: "none",

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[3],
    },
  },

  linkLabel: {
    marginRight: 5,
  },
  sublink: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    display: "block",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    lineHeight: 1,
    overflow: "hidden",
    padding: "10px 10px",
    textDecoration: "none",
    textOverflow: "ellipsis",

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  icon: {
    // transition: "transform 150ms ease",
    // transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));


export function HeaderMenuDocs() {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const navigate = useNavigate();
  const panel_opened = eval(reactLocalStorage.get("panel_opened"));

  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <a
        key={item.link}
        href={item.link}
        className={classes.sublink}
      >
        {item.label}
      </a>
    ));

    if (menuItems) {
      return (
        <div>
          <Menu
            exitTransitionDuration={0}
            onClose={() => setOpened(false)}
            onOpen={() => setOpened(true)}
            position="bottom-end"
            trigger="hover"
            width="target"
            key={link.label}
          >
            <Menu.Target>
              <a
                href={link.link}
                key={link.link}
                className={classes.link + " submenu"}
                onClick={(event) => (link.link == "#") ? event.preventDefault() : false}
                // onClick={(event) => event.preventDefault()}
              >
                  <span className={classes.linkLabel}>{link.label}</span>
                  <IconChevronDown size={12} className={classes.icon} stroke={1.5} />
              </a>
            </Menu.Target>
            <Menu.Dropdown>{menuItems}</Menu.Dropdown>
          </Menu>
        </div>
      );
    } else {
      return (
        <div>
          <a
            href={link.link}
            key={link.link}
            className={classes.link}
            onClick={(event) => (link.link == "#") ? event.preventDefault() : false}
          >
            {link.label}
          </a>
        </div>
      );
    }
  });

  const page_url = window.location.href;
  const date = moment().format();

  return (
    <div>
      <Header id="header" className={colorScheme} height={120}>
        <Container id="header_container" className={classes.container + " " + colorScheme} p={0}>
          <img id="logo_print" className={classes.logoPrint} width={120} height={60} src="logo_negative_horizontal.png" />
          <Text
           id="logo_url"
           ml={69}
           mb={10}
           className={classes.logoUrl}
           component="a"
           href={page_url}
          >
            {page_url}
          </Text>
          <Text
           id="logo_date"
           ml={69}
           className={classes.logoUrl}
           color="gray"
          >
            {date}
          </Text>
          <div>
            <a href="./" id="logo">
              <Image
                id="logo_img"
                width={LOGO_WIDTH}
                height={LOGO_HEIGHT}
                className={classes.logoImg}
                mt={15}
                ml={(panel_opened) ? 160 : 10}
                src={(colorScheme === "dark") ? "logo.png" : "logo_negative.png"}
              />
              <Divider className={classes.logoShadow} ml={(panel_opened) ? 195 : 45} variant="solid" />
            </a>
            <Stack align="end">
              <NavigationFeatures />

              <Group id="menu_docs" spacing={5} mt={-6} mr={10} className={classes.menu_docs}>
              {items}
              </Group>
              <Group id="menu_docs" spacing={5} mt={-10} mr={10}>
                <MobileMenu />
              </Group>
            </Stack>
          </div>
        </Container>
        <GoToTop />
      </Header>
    </div>
  );
}
