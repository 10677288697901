import { useState } from "react";
import {
  createStyles,
  Anchor,
  Breadcrumbs,
  Button,
  Divider,
  Drawer,
  Group,
  MediaQuery,
} from "@mantine/core";
import { IconChevronDown, IconMenu2 } from "@tabler/icons";
import { GetLanguage, SetLanguage, } from "../_utilities/languages";
import { GetMenu } from "../GetMenu/GetMenu";
import { NavigationFeatures } from "../HeaderMenuDocs/NavigationFeatures";

import menu from "../../data/json/menu.json";

const language = GetLanguage();
const language_id = language.id;
const links = menu[language_id as keyof typeof menu];

const MENU_HEIGHT = 50;
const LOGO_WIDTH = (69 * 1.5);
const LOGO_HEIGHT = (69 * 1.5);
const PADDING_TOP = (MENU_HEIGHT/1.5-1);

const useStyles = createStyles((theme) => ({
  link: {
    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    display: "block",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    lineHeight: 1,
    paddingBottom: "10px",
    paddingLeft: "35px",
    paddingRight: "10px",
    paddingTop: PADDING_TOP,
    textDecoration: "none",

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[3],
    },
  },
}));

export function MobileMenu() {
  const { classes } = useStyles();
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const menu = GetMenu();
  // console.warn(menu);

  return (
    <MediaQuery largerThan="sm" styles={{ display: "none !important" }}>
      <div id="mobile_menu">
        <a
          className={classes.link}
          href="#"
          onClick={(event) => { event.preventDefault(); setMobileMenuOpened(true) }}
        >
          <IconMenu2 size={20} />
        </a>

        <Drawer
          opened={mobileMenuOpened}
          onClose={() => setMobileMenuOpened(false)}
          title="Menu"
          padding="xl"
          size="xl"
          zIndex={100000000000000}
          position="right"
        >
        {menu}
        </Drawer>
      </div>
    </MediaQuery>
  );
}
