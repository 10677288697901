
import {reactLocalStorage} from "reactjs-localstorage";
import $ from "jquery";

var timeout:any = null;
export const hashRegex = /[\w\-\_]+/ig;
export const not_hashRegex = /[^\w\d\-\_\?]+/ig;

/**
 * Generate the hash
 * @use <reactLocalStorage>
 *
 * @param  {bool}               save                                Save in the localstorage? Default `true`
 * @return {string}             hash                                The generated hash string
 */
export function GenerateHash(save:boolean = true) {
  let characters ="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let hash = "";
  const charactersLength = characters.length;
  for ( let i = 0; i < 6; i++ ) {
    hash += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  // console.log(hash);
  // console.info("Generated URL", window.location.protocol + "//" + window.location.host + "/" + hash, hash);
  // history.pushState("", "", window.location.protocol + "//" + window.location.host + "/" + hash);
  if(save) {
    reactLocalStorage.set("hash", hash);
    // reactLocalStorage.set("selected_marker", "dynamic");
  }
  return hash;
}

/**
 * Regenerate the random hash
 * @use <reactLocalStorage>
 * @use SetMarkerType()
 *
 * @param  {bool}               force                               Force as static marker?
 * @param  {function}           setUrlLoaderVisible                 Function passed to show loader inside generated URL input
 * @return {string}             mapsource_address                   The MapSource address
 */
export function ReGenerateHash(force:boolean = false, setUrlLoaderVisible:any) {
  const selected_marker = reactLocalStorage.get("selected_marker");
  const marker_name_val = $("#marker_name").val() as string;

  return SetMarkerType(force, setUrlLoaderVisible);
};

/**
 * Change behaviour depending on marker type
 * @use <reactLocalStorage>
 *
 * @param  {bool}               force                               Force as static marker?
 * @param  {function}           setUrlLoaderVisible                 Function passed to show loader inside generated URL input
 * @return {string}             mapsource_address                   The MapSource address
 */
export function SetMarkerType(force:boolean = false, setUrlLoaderVisible:any) {
  const mapsource_address = GetMapSourceAddress();
  const marker_name_val = $("#marker_name").val() as string;


  if(reactLocalStorage.get("selected_marker") == "static" || force) {
    return DelayedSearch(mapsource_address, marker_name_val, setUrlLoaderVisible);
  } else {
    /**
     * Get the random hash
     */
    return GetRandomHash();
  }
}

/**
 * Get the current hash
 * @use <reactLocalStorage>
 *
 * @return {string}             hash                                The generated hash string
 */
export function GetHash() {
  var hash = reactLocalStorage.get("hash");
  if(!hash) {
    hash = GenerateHash();
  }
  return hash;
}

/**
 * Convert a given hash for URL:
 * - Replace spaces
 * - Remove all different from accepted characters (simple rule `[^\w\d\-\_\?]+`)
 * @param  {string}             hash                                A given hash string
 * @return {string}             hash                                The generated hash string
 */
export function CleanHash4URL(hash:any) {
  hash = hash
    .replace(/\s+/g, "-")
    .replace(/\-+/g, "-")
    .replace(/\_+/g, "_")
    .replace(/\?{4,}/g, "?_")
    .replace(/\?\_\?+/g, "?_")
    .replace(/\?\_\?\_+/g, "?_")
    .replace(/\?\?\_+/g, "?_")
    .replace(/\?\-\?\-+/g, "?-")
    .replace(/\-\_+/g, "-_")
    .replace(/\-\_\-\_+/g, "-_-")
    .replace(not_hashRegex, "")
    ;
  return hash;
}

/**
 * Generate the right URL for the addressbar
 * @param  {string}             hash                                An hash string
 * @return {string}                                                 The generated URL
 */
export function GetMapSourceAddress(hash:string = "") {
  if(hash == null || hash.length === 0) { const hash = GetHash(); }

  history.pushState("", "", window.location.protocol + "//" + window.location.host + "/" + CleanHash4URL(hash));
  return window.location.protocol + "//" + window.location.host + "/" + CleanHash4URL(hash);
}

/**
 * Get the marker name as hash and show STATIC Marker address
 * @use <reactLocalStorage>
 * @use jQuery
 *
 * @param  {string}             hash                                A given hash string
 * @param  {function}           setUrlLoaderVisible                 Function passed to show loader inside generated URL input
 * @return {string}             mapsource_address                   The MapSource address
 */
export function GetNameHash(hash:any, setUrlLoaderVisible:any) {
  // console.info(typeof setUrlLoaderVisible, setUrlLoaderVisible);

  const urlhash = CleanHash4URL(hash);
  reactLocalStorage.set("hash", urlhash);
  history.pushState("", "", window.location.protocol + "//" + window.location.host + "/" + urlhash);
  const mapsource_address = GetMapSourceAddress();
  setUrlLoaderVisible(false);

  $("#mapsource_address").css("padding-left", "90px").val(mapsource_address);
  $("#mapsource_badge").css({"color": "#22b8cf", "border-color": "#22b8cf"});
  $("#mapsource_badge .mantine-Badge-inner").text("STATICO");
  return mapsource_address;
}

/**
 * Get the random hash and show DYNAMIC Marker address
 * @use <reactLocalStorage>
 * @use jQuery
 *
 * @return {string}             mapsource_address                   The MapSource address
 */
export function GetRandomHash() {
  const hash = GenerateHash();
  const urlhash = CleanHash4URL(hash);
  reactLocalStorage.set("hash", urlhash);
  history.pushState("", "", window.location.protocol + "//" + window.location.host + "/" + urlhash);
  const mapsource_address = GetMapSourceAddress();

  $("#mapsource_address").val(mapsource_address);
  $("#mapsource_badge .mantine-Badge-inner").text("DINAMICO");
  $("#mapsource_badge").css({"color": "#ff6b6b", "border-color": "#ff6b6b"});
  $("#mapsource_address").css("padding-left", "100px");
  return mapsource_address;
}

/**
 * Delay the GET search query for address input data
 * @return {string}             mapsource_address                   The MapSource address
 * @param  {string}             marker_name_val                     The marker name input value
 * @param  {function}           setUrlLoaderVisible                 Function passed to show loader inside generated URL input
 * @return {string}             mapsource_address                   The MapSource address
 */
export function DelayedSearch(mapsource_address:string, marker_name_val:string, setUrlLoaderVisible:any) {
  // console.warn(typeof setUrlLoaderVisible, setUrlLoaderVisible);
  // console.warn(marker_name_val, setUrlLoaderVisible);

  if (timeout) {
    setUrlLoaderVisible;
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    /**
     * Get marker name as hash
     */
    const hash = (marker_name_val.length > 0) ? marker_name_val : "";
    // const mapsource_address = GetNameHash(hash, setUrlLoaderVisible);
    return mapsource_address + hash;
  }, 100);
}
