import { useState } from "react";
import {
  createStyles,
  Menu,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { GetLanguage, SetLanguage, } from "../_utilities/languages";

import menu from "../../data/json/menu.json";

const language = GetLanguage();
const language_id = language.id;
const links = menu[language_id as keyof typeof menu];

const MENU_HEIGHT = 50;
const LOGO_WIDTH = (69 * 1.5);
const LOGO_HEIGHT = (69 * 1.5);
const PADDING_TOP = (MENU_HEIGHT/1.5-1);

if(language_id == "english") {
  var MOON_PHASE_NAMES = [
    "New",
    "Waxing Crescent",
    "Quarter Moon",
    "Waxing Gibbous",
    "Full",
    "Waning Gibbous",
    "Last Quarter",
    "Waning Crescent",
  ];
} else if(language_id == "italiano") {
  var MOON_PHASE_NAMES = [
    "Nuova",
    "Crescente",
    "Quarto di luna",
    "Gibbosa crescente",
    "Piena",
    "Gibbosa calante",
    "Ultimo quarto",
    "Calante",
  ];
}

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  link: {
    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    display: "block",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    lineHeight: 1,
    padding: "20px 10px",
    textDecoration: "none",

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[3],
    },
  },

  linkLabel: {
    marginRight: 5,
  },
  sublink: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    display: "block",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    lineHeight: 1,
    overflow: "hidden",
    padding: "20px 10px",
    textDecoration: "none",
    textOverflow: "ellipsis",

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },
  icon: {
    // transition: "transform 150ms ease",
    // transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));
export function GetMenu() {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });

  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <a
        key={item.link}
        href={item.link}
        className={classes.sublink}
      >
        {item.label}
      </a>
    ));

    if (menuItems) {
      return (
        <div>
          <Menu
            exitTransitionDuration={0}
            onClose={() => setOpened(false)}
            onOpen={() => setOpened(true)}
            position="bottom-end"
            trigger="hover"
            width="target"
            key={link.label}
          >
            <Menu.Target>
              <a
                href={link.link}
                key={link.link}
                className={classes.link + " submenu"}
                onClick={(event) => (link.link == "#") ? event.preventDefault() : false}
                // onClick={(event) => event.preventDefault()}
              >
                  <span className={classes.linkLabel}>{link.label}</span>
                  <IconChevronDown size={12} className={classes.icon} stroke={1.5} />
              </a>
            </Menu.Target>
            <Menu.Dropdown>{menuItems}</Menu.Dropdown>
          </Menu>
        </div>
      );
    } else {
      return (
        <div>
          <a
            href={link.link}
            key={link.link}
            className={classes.link}
            onClick={(event) => (link.link == "#") ? event.preventDefault() : false}
          >
            {link.label}
          </a>
        </div>
      );
    }
  });
  return items;
}
