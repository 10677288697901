import { useState } from "react";
import {
  createStyles,
  Anchor,
  Breadcrumbs,
  Button,
  Divider,
  Drawer,
  Group,
  MediaQuery,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import { LanguagePicker } from "../LanguagePicker/LanguagePicker";
import { ColorSchemeToggle } from "../ColorSchemeToggle/ColorSchemeToggle";
import { GetLanguage, SetLanguage, } from "../_utilities/languages";

import { getMoonPhase } from "../getMoonPhase/getMoonPhase";
import "weather-icons/css/weather-icons.min.css"

import menu from "../../data/json/menu.json";
const language = GetLanguage();
const language_id = language.id;
const links = menu[language_id as keyof typeof menu];

const MENU_HEIGHT = 50;
const LOGO_WIDTH = (69 * 1.5);
const LOGO_HEIGHT = (69 * 1.5);
const PADDING_TOP = (MENU_HEIGHT/1.5-1);

const useStyles = createStyles((theme) => ({
  link: {
    borderTopLeftRadius: theme.radius.sm,
    borderTopRightRadius: theme.radius.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    display: "block",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    lineHeight: 1,
    paddingBottom: "10px",
    paddingLeft: "35px",
    paddingRight: "10px",
    paddingTop: PADDING_TOP,
    textDecoration: "none",

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[3],
    },
  },
}));

export function NavigationFeatures() {
  const { classes } = useStyles();
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const moon_phase = getMoonPhase();
  const moon_phase_label = (
    <Tooltip
      label="Luna oggi"
      color="gray"
      position="left"
      withArrow
    >
      <Group>
        <Stack pl={10} align="flex-end" spacing={0}>
          <Text fz="xs" component="b" color="auto">{moon_phase.name}</Text>
          <Text fz="xs" color="dimmed" component="small">giorno {moon_phase.lunar_day}</Text>
        </Stack>
        <Text component="i" className={moon_phase.icon} />
      </Group>
    </Tooltip>
  );

  return (
    <Group id="top_sidebar" mt={13} mr={10} mb={5}>
      {moon_phase_label}

      <MediaQuery smallerThan="sm" styles={{ display: "none !important" }}>
        <TextInput placeholder="Cerca nel sito" icon={<IconSearch size={16} />} />
      </MediaQuery>
      <ColorSchemeToggle />
      <LanguagePicker />
    </Group>
  );
}
