import { useEffect, useState } from "react";
import {reactLocalStorage} from "reactjs-localstorage";
import { createStyles, UnstyledButton, Menu, Image, Group } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { GetLanguage, SetLanguage, } from "../_utilities/languages";

import languages from "../../data/json/languages.json";
import menu from "../../data/json/menu.json";

const langs:any = [];
let lang: keyof typeof menu;
for(lang in menu) {
  langs.push(lang);
}
for (let l = 0; l < langs.length; l++) {
  const item = langs[l];
}

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  item: {
    lineHeight: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    textDecoration: "none",

    "&:hover": {
      borderRadius: 6,
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[3],
    },
  },
  subitem: {
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
    "&:hover": {
    },
  },

  linkLabel: {
    marginRight: 5,
  },

  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

export function LanguagePicker() {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });
  const [selected, setSelected] = useState(languages[0]);

  if(!GetLanguage()) {
    setSelected(GetLanguage());
  }

  const items = languages.map((item:any) => {
    if(langs.includes(item.id)) {
      if(item.id == GetLanguage().id) {
        useEffect(() => {
          setSelected(item);
        }, []);
      }
      return (
        <Menu.Item
          icon={<Image src={item.image} width={18} height={18} />}
          onClick={(event) => {
            setSelected(item);
            SetLanguage(item.label);
            window.location.reload();
            event.preventDefault();
          }}
          key={item.label}
        >
          {item.label}
        </Menu.Item>
      );
    }
  });

  return (
    <Menu
      id="menu_lang"
      exitTransitionDuration={0}
      onClose={() => setOpened(false)}
      onOpen={() => setOpened(true)}
      position="bottom-end"
      trigger="click"
      width="target"
    >
      <Menu.Target>
        <a
          href="#"
          className={classes.item}
          onClick={(event) => { event.preventDefault(); }}>
          <Group spacing="xs" className={classes.linkLabel}>
            <Image src={selected.image} width={22} height={22} />
          </Group>
          <IconChevronDown size={12} className={classes.icon} stroke={1.5} />
        </a>
      </Menu.Target>
      <Menu.Dropdown className={classes.subitem}>{items}</Menu.Dropdown>
    </Menu>
  );
}
