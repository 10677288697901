import React, { useState } from "react";
import {
  createStyles,
  ColorScheme,
  ColorSchemeProvider,
  Divider,
  Flex,
  MantineProvider,
  TypographyStylesProvider,
  useMantineColorScheme,
} from "@mantine/core";
import { useColorScheme, useLocalStorage } from "@mantine/hooks";

import { HeaderMenuDocs } from "../HeaderMenuDocs/HeaderMenuDocs";
import { Breadcrumb } from "../Breadcrumb/Breadcrumb";
import { LeftSidebar } from "../Sidebar/LeftSidebar";
import { RightSidebar } from "../Sidebar/RightSidebar";
import { Footer } from "../Footer/Footer";

const content = "<h3>Accedi</h3>";

const useStyles = createStyles((theme) => ({
  contents: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    position: "static",
    boxSizing: "border-box",
    maxWidth: "calc(100% - 225px)",
    width: "100%"
  },
  html_content: {
  },
}));

export function Login() {
  const { classes, theme } = useStyles();
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "colorScheme",
    defaultValue: preferredColorScheme,
  });
  const toggleColorScheme = (value?:ColorScheme) => setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ colorScheme }}
      >
        <HeaderMenuDocs />
        <Divider variant="dotted" />
        <div className={classes.contents}>
          <Flex
            mih={50}
            gap="md"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
            mt={-25}
            py={0}
            px={0}
            w="100%"
          >
            <LeftSidebar />
            <TypographyStylesProvider
              id="contents"
              className={classes.contents}
              ml={-16}
              p={19}
            >
              <Breadcrumb />
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </TypographyStylesProvider>
            <RightSidebar />
          </Flex>
        </div>
        <Footer />
      </MantineProvider>
  </ColorSchemeProvider>
  );
}
