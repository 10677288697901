import React, { useState } from "react";
import {
  createStyles,
  ColorScheme,
  ColorSchemeProvider,
  Button,
  Divider,
  Flex,
  Group,
  MantineProvider,
  MediaQuery,
  Text,
  Textarea,
  TextInput,
  TypographyStylesProvider,
  useMantineColorScheme,
} from "@mantine/core";
import { IconMailFast } from "@tabler/icons";
import { useColorScheme, useLocalStorage } from "@mantine/hooks";

import { HeaderMenuDocs } from "../HeaderMenuDocs/HeaderMenuDocs";
import { Breadcrumb } from "../Breadcrumb/Breadcrumb";
import { SidebarEmpty } from "../SidebarEmpty/SidebarEmpty";
import { Footer } from "../Footer/Footer";

const useStyles = createStyles((theme) => ({
  contents_container: {
    paddingLeft: 20,
    width: "100%",
  },
  contents: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    position: "static",
    boxSizing: "border-box",
    width: "100%",
  },
  contactForm: {
    [theme.fn.largerThan("sm")]: {
      width: "80%",
    },
    [theme.fn.largerThan("lg")]: {
      width: "60%",
    },
  },
}));

export function Contacts() {
  const { classes, theme } = useStyles();
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "colorScheme",
    defaultValue: preferredColorScheme,
  });
  const toggleColorScheme = (value?:ColorScheme) => setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const content = (
    <div>
      <Text component="h3">Contatti</Text>
      <Text mb={60}>
        Dubbi? Domande? Feedback?<br />
        Contattaci per fare una segnalazione, darci un feedback e perché no, richiedere nuove funzionalità
      </Text>
      <div className={classes.contactForm}>
        <TextInput
          label="Email"
          placeholder="tua@email.com"
          required
        />
        <TextInput
          label="Nome"
          placeholder="John Doe"
          required
          mt="md"
        />
        <Textarea
          required
          label="Messaggio"
          placeholder="Inserisci del testo qui..."
          minRows={4}
          mt="md"
        />

        <Group position="right" mt="md">
          <Button color="blue.5" rightIcon={<IconMailFast />}>Invia</Button>
        </Group>
      </div>
    </div>
  );
  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{ colorScheme }}
    >
      <HeaderMenuDocs />
      <Divider variant="dotted" />
      <Group position="left" sx={{ alignItems: "flex-start !important" }}>
        <MediaQuery smallerThan="sm" styles={{ display: "none !important" }}>
          <div>
            <SidebarEmpty panel_openable={false} />
          </div>
        </MediaQuery>
        <TypographyStylesProvider
          id="contents"
          className={classes.contents}
          ml={-16}
          p={19}
        >
          <div id="pdf">
            <Breadcrumb />
            <div className={classes.contents_container}>
              {content}
            </div>
          </div>
        </TypographyStylesProvider>
      </Group>
      <Footer />
     </MantineProvider>
    </ColorSchemeProvider>
  );
}
