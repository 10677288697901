import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
// Local Storage
import {reactLocalStorage} from "reactjs-localstorage";
import $ from "jquery";
import {
  createStyles,
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Card,
  Center,
  Checkbox,
  CheckboxProps,
  Container,
  FileInput,
  Flex,
  FocusTrap,
  Grid,
  Group,
  Image,
  Radio,
  Select,
  SimpleGrid,
  Stack,
  Tabs,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  UnstyledButton,
  useMantineTheme
} from "@mantine/core";
import {
  randomId,
  useDisclosure,
  useInputState,
  useListState,
} from "@mantine/hooks";
import {
  IconAdjustments,
  IconArrowBigDownLine,
  IconChartDots3,
  IconChevronLeft,
  IconDeviceFloppy,
  IconHash,
  IconMapPin,
  IconInfoCircle,
  IconListCheck,
  IconListDetails,
  IconLock,
  IconMap2,
  IconUsers,
  IconPolygon,
  IconTooltip,
} from "@tabler/icons";

import { StaticMarker, DynamicMarker } from "../AddMarkerForm/AddMarkerForm";


import {
  GenerateHash,
  ReGenerateHash,
  GetHash,
  CleanHash4URL,
  GetMapSourceAddress,
  GetNameHash,
  GetRandomHash,
  DelayedSearch,
} from "../_utilities/hash";
import main_buttons from "../../data/json/add_marker_buttons.json";
const content = "<p>Testo di esempio</p><br /><br />";

/**
 * Local sass styles
 * @type {function}
 */
const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.radius.md,
    // height: 90,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      // boxShadow: `${theme.shadows.md} !important`,
      transform: "scale(1.05)",
      boxShadow: "0 0 9px #fff",
    },
    img: { objectPosition: "center -25px", },
  },
  itemDescription: { padding: "10px 5px 10px 0" },
  itemDescriptionLine: {},
}));

type ActionsGridProps = {
  urlLoaderVisible: boolean;
  setAddress: Dispatch<SetStateAction<string>>;
};
export const ActionsGrid: React.FC<ActionsGridProps> = ({ urlLoaderVisible, setAddress }) => {
  // console.info(urlLoaderVisible, typeof urlLoaderVisible);
  const { classes, theme } = useStyles();
  const [clickedButton, setClickedButton] = useState("");
  const [activeTab, setActiveTab] = useState<string | null>("tab1");
  const [active, handlers] = useDisclosure(true);
  const [opened, setOpened] = useState(true);
  // const [values, valueHandlers] = useListState(initialValues);

  // const allChecked = values.every((value) => value.checked);
  // const indeterminate = values.some((value) => value.checked) && !allChecked;

  // const checkboxes = values.map((value, index) => (
  //   <Checkbox
  //     mt="xs"
  //     ml={33}
  //     label={value.label}
  //     key={value.key}
  //     checked={value.checked}
  //     onChange={(event) => valueHandlers.setItemProp(index, "checked", event.currentTarget.checked)}
  //   />
  // ));
  const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    // event.preventDefault();

    const button: HTMLButtonElement = event.currentTarget;
    console.log("ActionsGrid: clicked", button.id);

    const mapsource_address = GetMapSourceAddress();
    $("#mapsource_address").val(mapsource_address);
    if(button.id == "point_btn") {
      setActiveTab("tab2");
      setClickedButton(button.id);
      var marker_name:string = $("#marker_name").val() as string;
      const mapsource_address = GetMapSourceAddress(marker_name);
      reactLocalStorage.set("hash", marker_name);
      reactLocalStorage.set("selected_marker", "static");
      $("#mapsource_address").css("padding-left", "90px").val(mapsource_address);
      $("#mapsource_badge").css({"color": "#22b8cf", "border-color": "#22b8cf"});
      $("#mapsource_badge .mantine-Badge-inner").text("STATICO");
      setTimeout(() => {
        $("#marker_name").focus();
      }, 10);
    }
    if(button.id == "private_point_btn") {
      setActiveTab("tab3");
      setClickedButton(button.id);
      const hash = GenerateHash();
      const urlhash = CleanHash4URL(hash);
      const mapsource_address = GetMapSourceAddress(urlhash);
      history.pushState("", "", mapsource_address);
      reactLocalStorage.set("hash", urlhash);
      reactLocalStorage.set("selected_marker", "dynamic");
      $("#mapsource_address").val(mapsource_address);
      $("#mapsource_address").css("padding-left", "100px").val(mapsource_address);
      $("#mapsource_badge").css({"color": "#ff6b6b", "border-color": "#ff6b6b"});
      $("#mapsource_badge .mantine-Badge-inner").text("DINAMICO");
      setTimeout(() => {
        $("#dynamic_marker_title").focus();
      }, 10);
    }
  };

  const items = main_buttons.map((item) => (
    <UnstyledButton
      id={item.id}
      key={item.title}
      className={classes.item}
      onClick={buttonHandler}
      >
      <Card shadow="sm" sx={{ width: "98%", height: "97%" }}>
        <Card.Section>
          <Image src={item.image} fit="cover" height={250} alt={item.title} />
        </Card.Section>

        <Group position="apart" mt="md" mb="xs">
          <Text size="xl" weight={500} transform="uppercase">{item.title}</Text>
          <Stack align="flex-end" justify="flex-start" spacing="xs">
            <Badge color={item.badge_colour} variant="outline" className={classes.itemDescriptionLine}>
              {item.description2}
            </Badge>
            <Badge color={item.badge_colour2} className={classes.itemDescriptionLine}>
              {item.description3}
            </Badge>
          </Stack>
        </Group>

        <Text size="md" px={4} color="dimmed">
          {item.description}
          <br />
          <Text className={classes.itemDescriptionLine} size="lg" color="grey" mt={6} mb={0}>
            {item.description1}
          </Text>
        </Text>
      </Card>
    </UnstyledButton>
  ));
  const BackButton = function() {
    return (
      <Button
        id="back_btn"
        mb={10}
        pl={0}
        pr={10}
        size="xs"
        color="teal"
        variant="subtle"
        radius="md"
        onClick={(event) => {
          event.preventDefault()
          setActiveTab("tab1");
          setClickedButton("back_btn");
        }}
        >
          <IconChevronLeft size={12} /> <Text mt={4} ml={4}>INDIETRO</Text>
      </Button>
    );
  }

  return (
    <Tabs defaultValue="first" value={activeTab} onTabChange={setActiveTab}>
      <Tabs.Panel value={"tab1"} mb={20}>
        <Card radius="md" mt={40} id="main_action_menu" className={classes.card} px={35} py={30}>
          <SimpleGrid cols={2} spacing={40}>
            {items}
          </SimpleGrid>
        </Card>
      </Tabs.Panel>

      <Tabs.Panel value={"tab2"}>
        <BackButton />
        <StaticMarker urlLoaderVisible={urlLoaderVisible} setAddress={setAddress} />
      </Tabs.Panel>

      <Tabs.Panel value={"tab3"}>
        <BackButton />
        <DynamicMarker />
      </Tabs.Panel>
    </Tabs>
  );
};
// export default ActionsGrid;
