import { Dispatch, SetStateAction, forwardRef, useEffect, useState, FC } from "react";
// Local Storage
import {reactLocalStorage} from "reactjs-localstorage";
import $ from "jquery";
import {
  createStyles,
  ActionIcon,
  Anchor,
  Badge,
  Blockquote,
  Button,
  Card,
  Center,
  Checkbox,
  CheckboxProps,
  Code,
  Container,
  FileInput,
  Flex,
  FocusTrap,
  Grid,
  Group,
  Image,
  Indicator,
  Loader,
  LoadingOverlay,
  Mark,
  MultiSelect,
  MultiSelectProps,
  MultiSelectValueProps,
  Radio,
  Select,
  SelectItemProps,
  SimpleGrid,
  Tabs,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Title,
  UnstyledButton,
  useMantineTheme
} from "@mantine/core";
import {
  randomId,
  useDisclosure,
  useFocusTrap,
  useInputState,
  useListState,
  useScrollIntoView,
  // useFocusWithin,
  useMediaQuery,
} from "@mantine/hooks";
import {
  IconAdjustments,
  IconArrowBigDownLine,
  IconChartDots3,
  IconChevronLeft,
  IconDeviceFloppy,
  IconHash,
  IconInfoCircle,
  IconListCheck,
  IconListDetails,
  IconLock,
  IconMap,
  IconMap2,
  IconMapPin,
  IconPolygon,
  IconSettings,
  IconTag,
  IconTooltip,
} from "@tabler/icons";

// Form
// import { UserFormProvider, useUserForm } from "./form-context";
import { DatePicker, DateRangePicker, TimeInput } from "@mantine/dates";

// JSON
import marker_type from "../../data/json/marker_type.json";
import organization_type from "../../data/json/organization_type.json";

// Text editor
import { useEditor } from "@tiptap/react";
import { RichTextEditor, Link } from "@mantine/tiptap";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
// import { useForm } from "@mantine/form";
import { createFormContext } from "@mantine/form";
import dayjs from "dayjs";



/**
 * Static form interface
 * @type {object}
 */
interface StaticFormValues {
  title: string,
  marker_type?: string | undefined,
  organization_type?: string | undefined,
  cloud_title: string;
  description: string;
  date: string;
  url: string;
  image: string;
  file: string;
}
interface DynamicFormValues {
  cloud_title: string;
  description: string;
  expiration: string;
  date: string;
  url: string;
  image: string;
  file: string;
}
const [FormProvider, useFormContext, useForm] = createFormContext();

import {
  hashRegex,
  not_hashRegex,
  GenerateHash,
  ReGenerateHash,
  GetHash,
  CleanHash4URL,
  GetMapSourceAddress,
  GetNameHash,
  GetRandomHash,
  DelayedSearch,
} from "../_utilities/hash";
/**
 * Define the user maker types array
 * @type {array}
 */
const user_marker_type = marker_type.map((item) => { return item.value; });
const CheckboxIcon: CheckboxProps["icon"] = ({ indeterminate, className }) => indeterminate ? <IconLock className={className} /> : <IconLock className={className} />;
const content = "";

/**
 * Local sass styles
 * @type {function}
 */
const useStyles = createStyles((theme) => ({
  form: {},
  input: {},
  inputLabel: {},
  control: { paddingTop: "7.5px", },
  title_save_btn: {
    justifyContent: "space-between !important",
    padding: "0 !important",

    svg: {
      marginTop: "-7.5px",
    }
  }
}));
const today = formatDate(new Date());

function create_tag(query:string) {
  let regex = "/[^\w\d\#]+/gi"
  let tag = "#" + query.replace(regex, "-");
  return tag;
}
function padTo2Digits(num:number) {
  return num.toString().padStart(2, "0");
}
function formatDate(date:any) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
}



const Asterisk = function() {
  return (<Text component="span" color="red">*</Text>);
}

interface BackAndSaveButtonInterface {
    type: string;
    groupClass: any;
    itemClass: any;
    icon: any
}
const BackAndSaveButton: FC<BackAndSaveButtonInterface> = ({type, groupClass, itemClass, icon}) => {
  if(type == "static") {
    return (
      <Group position="apart" className={groupClass}>
        <Group position="left">
          {icon} Marker statico
        </Group>
        <Button
          type="submit"
          leftIcon={<IconDeviceFloppy />}
          variant="outline"
          color="cyan"
          size="xs"
          uppercase
          className={itemClass}
        >Salva</Button>
      </Group>
    );
  } else {
    return (
      <Group position="apart" className={groupClass}>
        <Group position="left">
          {icon} Marker temporaneo
        </Group>
        <Button
          type="submit"
          leftIcon={<IconDeviceFloppy />}
          variant="outline"
          color="cyan"
          size="xs"
          uppercase
          className={itemClass}
        >Salva</Button>
      </Group>
    );
  }
};
export default BackAndSaveButton;

type StaticMarkerProps = {
  urlLoaderVisible: boolean;
  setAddress: Dispatch<SetStateAction<string>>;
};
export const StaticMarker: React.FC<StaticMarkerProps> = ({ urlLoaderVisible, setAddress }) => {
  // console.info("qui?", setAddress, typeof setAddress);
  const { classes, theme } = useStyles();
  const focusTrapRef = useFocusTrap();
  // const [data, setData] = useState(marker_type);
  const [marker_name, setMarkerName] = useState("");
  const [markerType, setMarkerType] = useState(marker_type);
  // const [organization_type, setOrganizationType] = useState<MultiSelectProps | null>(null);
  const [organizationType, setOrganizationType] = useState(organization_type);
  const [value, setValue] = useState("");
  const [cloudTitle, setCloudTitle] = useInputState("");
  const [searchValue, onSearchChange] = useState("");
  const [radioValue, setRadioValue] = useState("react");
  const [image, setImage] = useState<File | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const [submittedValues, setSubmittedValues] = useState("");
  const [visible, setVisible] = useState(false);
  const isMobile = useMediaQuery("(max-width: 755px)");

  const form = useForm({
    initialValues: {
      title: "",
      name: "",
      marker_type: "",
      organization_type: "",
      cloud_title: "",
      description: "",
      date: "",
      url: "",
      image: "",
      file: "",
    },
    validate: {
      name: (value:string) => (value.length < 1 ? "Devi specificare un nuome per il marker" : null),
      marker_type: (value:string) => (value.length < 1 ? "Devi specificare il tipo di marker" : null),
      organization_type: (value:string) => (value.length < 1 ? "Devi specificare il tipo di Organizzazione" : null),
      title: (value:string) => (value.length < 1 ? "Aggiungi un titolo per la nuvoletta" : null),
    },
  });
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content,
  });

  // const result = event.target.value.replace(/[^a-z]/gi, "");

  const handleMarkerName = (event:any) => {
    // setMarkerName(event.currentTarget.value.replace(not_hashRegex, ""))
    // if((e.charCode >= 65 && e.charCode <= 90) || (e.charCode >= 97 && e.charCode <= 122)) {
      // const result = e.target.value.replace(/[^a-z]/gi, "");
      // const result = e.target.value.replace(/[^a-z]/gi, "");
      // setMessage(result);

      const cleaned_hash = CleanHash4URL(event.currentTarget.value);
      reactLocalStorage.set("hash", cleaned_hash);
      history.pushState("", "", window.location.protocol + "//" + window.location.host + "/" + cleaned_hash);
      var mapsource_address = GetMapSourceAddress(cleaned_hash);
      // console.info("AddMarkerForm.tsx", typeof setAddress, mapsource_address);
      // console.info("AddMarkerForm.tsx", typeof setAddress, mapsource_address);
      // setAddress(mapsource_address);

      // const urlhash = CleanHash4URL(hash);
      // reactLocalStorage.set("hash", urlhash);
      // history.pushState("", "", window.location.protocol + "//" + window.location.host + "/" + urlhash);
      // var mapsource_address:string = ReGenerateHash(true, setUrlLoaderVisible)!;
      $("#mapsource_address").val(mapsource_address);
      // setAddress(mapsource_address);
      // GetMapSourceAddress();
      // }
      setMarkerName(cleaned_hash);
    };

  // const keyUpFunction = (e) => {
  //   console.info(target);
  //   const hash = reactLocalStorage.get("hash");
  //   var mapsource_address = GetMapSourceAddress();
  //   console.log(mapsource_address, hash, GetMapSourceAddress());
  //   // setAddress(mapsource_address);
  //
  //   // GetMapSourceAddress();
  // };
  const indigo66 = theme.colors.indigo[6];
  // $(document).ready(() => {
  //   $("#marker_name").focus();
  // })
  return (
    <FormProvider form={form}>
      <form onSubmit={form.onSubmit((values) => { console.log(values) })} className={classes.form}>
        <BackAndSaveButton
          type="static"
          groupClass={classes.title_save_btn}
          itemClass={classes.control}
          icon={<IconMapPin color={indigo66} size={30} />}
        />
        <Blockquote
          fz="sm"
          mt={20}
          pl={0}
          pr={90}
          icon={<IconInfoCircle size={24} />}
        >
          Scegli un <Text fw={700} span={true}>nome</Text> per il tuo marker, poi assegna dei <Text fw={700} span={true}>tag</Text> per definirne il tipo, infine specifica il tuo <Text fw={700} span={true}>tipo di Organizzazione</Text>.<br />
          Crea un <Text fw={700} span={true}>titolo</Text> e del <Text fw={700} span={true}>contenuto testuale</Text> da inserire nella nuvoletta, nei dati aggiuntivi puoi allegare dei <Text fw={700} span={true}>files</Text> e un <Text fw={700} span={true}>indirizzo web</Text> da mostrare.<br />
        </Blockquote>

        <Tabs color="cyan" defaultValue="resource_type" mt={30}>
          <Tabs.List>
            <Tabs.Tab value="resource_type" icon={<IconSettings size={18} />}>Tipo di risorsa <Asterisk /></Tabs.Tab>
            <Tabs.Tab value="tooltip_form" icon={<IconTooltip size={18} />}>Tooltip <Asterisk /></Tabs.Tab>
            <Tabs.Tab value="data_form" icon={<IconListDetails size={18} />}>Dati aggiuntivi</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="resource_type">
              <div ref={focusTrapRef}>
                  <TextInput
                    id="marker_name"
                    name="name"
                    description='Il nome del marker diventerà il suo URL, sono consentiti solo caratteri di testo, numeri, "?" e "-"'
                    label="Nome del Marker"
                    placeholder="Inserisci qui il nome del tuo marker"
                    mt="sm"
                    mb={10}
                    size="lg"
                    data-autofocus
                    styles={(theme) => ({
                      input: {
                        "&:focus-within": {
                          borderColor: "teal",
                          borderWidth: "2px",
                        },
                      },
                    })}
                    /*value={reactLocalStorage.get("hash")}
                    defaultValue={marker_name}*/
                    value={marker_name}
                    required
                    rightSection={urlLoaderVisible && <Loader variant="oval" color="#1971c2" size="xs" />}
                    onChange={handleMarkerName}
                    /* { ...form.getInputProps("marker_name")} */
                    /*onChange={(event) => setMarkerName(event.currentTarget.value.replace(not_hashRegex, ""))}*/

                  />
              </div>
            <MultiSelect
              data={markerType}
              name="marker_type"
              label="Tipo di Marker"
              description="Seleziona o crea i tag per identificare il tipo di marker, comparirà nelle ricerche degli utenti."
              placeholder="Seleziona tutti quelli che si addicono"
              nothingFound="Nessuna opzione"
              clearButtonLabel="Cancella tutto"
              dropdownPosition="flip"
              switchDirectionOnFlip={true}
              selectOnBlur={true}
              required
              searchable
              clearable
              creatable
              mt="lg"
              mb={30}
              maxDropdownHeight={350}
              getCreateLabel={(query) => {
                let tag = create_tag(query);

                if(!user_marker_type.includes(query) && !user_marker_type.includes(tag)) {
                  return `+ Crea la voce ${tag}`
                } else {
                  return null;
                }
              }}
              onCreate={(query) => {
                let tag = create_tag(query);
                const item = { value: tag, label: tag };

                // console.log(user_marker_type, tag, user_marker_type.includes(tag));
                if(!user_marker_type.includes(query) && !user_marker_type.includes(tag)) {
                  user_marker_type.push(tag);
                  setMarkerType((current:any) => [...current, item]);
                  return item.value;
                } else {
                  return null;
                }
              }}
              icon={<IconTag size={16} />}
              {...form.getInputProps("marker_type")}
            />
            <Select
              data={organizationType}
              name="organization_type"
              label="Tipo di organizzazione"
              description="Specifica il tuo tipo di Organizzazione. Sono escluse le Società Commerciali di medio-grande entità"
              placeholder="Seleziona il tipo di organizzazione"
              dropdownPosition="flip"
              withAsterisk
              mt="lg"
              mb="lg"
              required
              maxDropdownHeight={350}
              {...form.getInputProps("organization_type")}
            />
          </Tabs.Panel>

          <Tabs.Panel value="tooltip_form">
            <TextInput
              name="cloud_title"
              label="Titolo"
              description="Titolo della nuvoletta"
              placeholder="Titolo della georeferenza"
              required
              classNames={{ input: classes.input, label: classes.inputLabel }}
              mt="lg"
              mb="lg"
              defaultValue={cloudTitle}
              // onChange={setCloudTitle}
              data-autofocus
              {...form.getInputProps("cloud_title")}
            />
            <Text size={14} mt={40} mb={4}>Descrizione</Text>
            <RichTextEditor
              editor={editor}
              mb="lg"
              // name="description"
              {...form.getInputProps("description")}
            >
              <RichTextEditor.Toolbar>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Bold />
                  <RichTextEditor.Italic />
                  <RichTextEditor.Underline />
                  <RichTextEditor.Strikethrough />
                  <RichTextEditor.ClearFormatting />
                  <RichTextEditor.Highlight />
                  <RichTextEditor.Code />
                </RichTextEditor.ControlsGroup>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.H1 />
                  <RichTextEditor.H2 />
                  <RichTextEditor.H3 />
                  <RichTextEditor.H4 />
                </RichTextEditor.ControlsGroup>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Blockquote />
                  <RichTextEditor.Hr />
                  <RichTextEditor.BulletList />
                  <RichTextEditor.OrderedList />
                  <RichTextEditor.Subscript />
                  <RichTextEditor.Superscript />
                </RichTextEditor.ControlsGroup>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Link />
                  <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.AlignLeft />
                  <RichTextEditor.AlignCenter />
                  <RichTextEditor.AlignJustify />
                  <RichTextEditor.AlignRight />
                </RichTextEditor.ControlsGroup>
              </RichTextEditor.Toolbar>
              <RichTextEditor.Content />
            </RichTextEditor>
          </Tabs.Panel>

          <Tabs.Panel value="data_form">
            <Flex
              justify="flex-start"
              align="center"
              direction="row"
              wrap="wrap"
              mt="md"
              mb="sm"
            >
              <DatePicker
                name="date"
                label="Data di validità"
                description="Data di validità di questo marker"
                placeholder="Seleziona una data"
                firstDayOfWeek="monday"
                inputFormat="DD/MM/YYYY"
                labelFormat="DD/MM/YYYY"
                defaultValue={new Date()}
                allowLevelChange={false}
                clearable={false}
                renderDay={(date) => {
                  const all_dates = formatDate(date);
                  return (
                    <Indicator size={6} color="red" offset={8} disabled={all_dates !== today}>
                      <div>{date.getDate()}</div>
                    </Indicator>
                  );
                }}
                dropdownType={isMobile ? "modal" : "popover"}
                minDate={dayjs(new Date()).toDate()}
              />
            </Flex>
            <Grid>
              <Grid.Col span={10}>
                <TextInput
                  name="url"
                  label="Indirizzo web (URL)"
                  placeholder="http://..."
                  mt="sm"
                  mb="sm"
                  defaultValue=""
                  {...form.getInputProps("url")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={8}>
                <FileInput
                  name="image"
                  placeholder="Immagine"
                  label="Carica un'immagine"
                  mt="sm"
                  mb="sm"
                  defaultValue={image}
                  // onChange={setImage}
                  {...form.getInputProps("image")}
                />
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={8}>
                <FileInput
                  name="file"
                  placeholder="File"
                  label="Carica un file"
                  mt="sm"
                  mb="sm"
                  defaultValue={file}
                  // onChange={setFile}
                  {...form.getInputProps("file")}
                />
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
            </Grid>
          </Tabs.Panel>
        </Tabs>
      </form>
    </FormProvider>
  );
}

export function DynamicMarker() {
  const { classes, theme } = useStyles();
  const [title, setTitle] = useInputState("");
  const [radioValue, setRadioValue] = useState("react");
  const [image, setImage] = useState<File | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [checkbox, setCheckbox] = useState<string[]>([]);
  const [submittedValues, setSubmittedValues] = useState("");

  const isMobile = useMediaQuery("(max-width: 755px)");
  const focusTrapRef = useFocusTrap();

  const form = useForm({
    initialValues: {
      title: "",
      description: "",
      date: "",
      expiration: "",
      url: "",
      image: "",
      file: "",
    },
    validate: {
      title: (value:string) => (value.length < 1 ? "Aggiungi un titolo" : null),
      date: (value:string) => (value.length < 1 ? "La data è necessaria" : null),
      expiration: (value:string) => (value.length < 1 ? "Devi specificare la durata" : null),
    },
  });
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
    ],
    content,
  });

  const formSubmit = function(values:any) {
    console.log(values);
    // const html = editor.getHTML();
    console.info(JSON.stringify(values, null, 2));
    setSubmittedValues(JSON.stringify(values, null, 2));
  }
  const violet66 = theme.colors.violet[6];
  return (
    <FormProvider form={form}>
      <form onSubmit={form.onSubmit((values) => { console.log(values) })} className={classes.form}>
        <BackAndSaveButton
          type="dynamic"
          groupClass={classes.title_save_btn}
          itemClass={classes.control}
          icon={<IconMap2 color={violet66} size={30} />}
        />
        <Blockquote
          fz="sm"
          mt={20}
          pl={0}
          pr={90}
          icon={<IconInfoCircle size={24} />}
        >
          Crea un <Text fw={700} span={true}>titolo</Text> e del <Text fw={700} span={true}>contenuto testuale</Text> da inserire nella nuvoletta.<br />
          Nei dati aggiuntivi specifica la <Text fw={700} span={true}>durata del marker</Text> (ora, giorno, settimana, mese), puoi allegare dei <Text fw={700} span={true}>files</Text> e un <Text fw={700} span={true}>indirizzo web</Text> da mostrare.
        </Blockquote>

        <Tabs color="cyan" defaultValue="tooltip_form" mt={30}>
          <Tabs.List>
            <Tabs.Tab value="tooltip_form" icon={<IconTooltip size={18} />}>Tooltip <Asterisk /></Tabs.Tab>
            <Tabs.Tab value="data_form" icon={<IconListDetails size={18} />}>Dati aggiuntivi <Asterisk /></Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="tooltip_form">
            <TextInput
              id="dynamic_marker_title"
              name="title"
              label="Titolo"
              placeholder="Titolo della georeferenza"
              required
              classNames={{ input: classes.input, label: classes.inputLabel }}
              mt="lg"
              mb="lg"
              defaultValue={title}
              // onChange={setTitle}
              data-autofocus
              {...form.getInputProps("title")}
            />
            <Text size={14} mt={40} mb={4}>Descrizione</Text>
            <RichTextEditor
              editor={editor}
              mb="lg"
              // name="description"
              {...form.getInputProps("description")}
            >
              <RichTextEditor.Toolbar>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Bold />
                  <RichTextEditor.Italic />
                  <RichTextEditor.Underline />
                  <RichTextEditor.Strikethrough />
                  <RichTextEditor.ClearFormatting />
                  <RichTextEditor.Highlight />
                  <RichTextEditor.Code />
                </RichTextEditor.ControlsGroup>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.H1 />
                  <RichTextEditor.H2 />
                  <RichTextEditor.H3 />
                  <RichTextEditor.H4 />
                </RichTextEditor.ControlsGroup>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Blockquote />
                  <RichTextEditor.Hr />
                  <RichTextEditor.BulletList />
                  <RichTextEditor.OrderedList />
                  <RichTextEditor.Subscript />
                  <RichTextEditor.Superscript />
                </RichTextEditor.ControlsGroup>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Link />
                  <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.AlignLeft />
                  <RichTextEditor.AlignCenter />
                  <RichTextEditor.AlignJustify />
                  <RichTextEditor.AlignRight />
                </RichTextEditor.ControlsGroup>
              </RichTextEditor.Toolbar>
              <RichTextEditor.Content />
            </RichTextEditor>
          </Tabs.Panel>

          <Tabs.Panel value="data_form">
            <Group>
              <DatePicker
                name="date"
                label="Data di validità"
                description="Data di validità del marker, visibile dalle ore 00:00 e successive"
                placeholder="Seleziona una data"
                firstDayOfWeek="monday"
                inputFormat="DD/MM/YYYY"
                labelFormat="DD/MM/YYYY"
                defaultValue={new Date()}
                allowLevelChange={false}
                clearable={false}
                required
                renderDay={(date) => {
                  const all_dates = formatDate(date);
                  return (
                    <Indicator size={6} color="red" offset={8} disabled={all_dates !== today}>
                      <div>{date.getDate()}</div>
                    </Indicator>
                  );
                }}
                dropdownType={isMobile ? "modal" : "popover"}
                minDate={dayjs(new Date()).toDate()}
              />

              <Select
                name="expiration"
                label=""
                description="Seleziona una durata"
                required
                mt={34}
                data={[
                  { value: "1h", label: "1 ora" },
                  { value: "1d", label: "1 giorno" },
                  { value: "1w", label: "1 settimana" },
                  { value: "1m", label: "1 mese" },
                ]}
                defaultValue="1d"
              />
            </Group>
            <Grid>
              <Grid.Col span={10}>
                <TextInput
                  name="url"
                  label="Indirizzo web (URL)"
                  placeholder="http://..."
                  classNames={{ input: classes.input, label: classes.inputLabel }}
                  mt="sm"
                  mb="sm"
                  {...form.getInputProps("url")}
                />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={8}>
                <FileInput
                  name="image"
                  placeholder="Immagine"
                  label="Carica un'immagine"
                  mt="sm"
                  mb="sm"
                  defaultValue={image}
                  // onChange={setImage}
                  {...form.getInputProps("image")}
                />
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={8}>
                <FileInput
                  name="file"
                  placeholder="File"
                  label="Carica un file"
                  mt="sm"
                  mb="sm"
                  defaultValue={file}
                  // onChange={setFile}
                  {...form.getInputProps("file")}
                />
              </Grid.Col>
              <Grid.Col span={4}></Grid.Col>
            </Grid>
          </Tabs.Panel>
        </Tabs>
      </form>
    </FormProvider>
  );
}
