import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { reactLocalStorage } from "reactjs-localstorage";
import {
  createStyles,
  Badge,
  Box,
  Button,
  Card,
  Center,
  ColorScheme,
  ColorSchemeProvider,
  Container,
  Divider,
  Flex,
  Group,
  Image,
  List,
  Loader,
  MantineProvider,
  MediaQuery,
  SegmentedControl,
  SimpleGrid,
  Space,
  Stack,
  Text,
  TypographyStylesProvider,
  UnstyledButton,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useColorScheme, useLocalStorage } from "@mantine/hooks";
import { IconThumbUp, IconCheck, IconCircleCheck, IconArrowUpRight } from "@tabler/icons";
import axios from "axios";

import { GetCurrentLocation } from "../_utilities/common";
import { HeaderMenuDocs } from "../HeaderMenuDocs/HeaderMenuDocs";
import { Breadcrumb } from "../Breadcrumb/Breadcrumb";
import { SidebarEmpty } from "../SidebarEmpty/SidebarEmpty";
import { RightSidebar } from "../Sidebar/RightSidebar";
import { Footer } from "../Footer/Footer";

const content = "<h3>Piani tariffari</h3>";
const panel_opened = false;
const panel_openable = false;
// reactLocalStorage.set("panel_opened", false);

const useStyles = createStyles((theme) => {
  return {
    contents_container: {
      width: "100%",
    },
    contents: {
      width: "100%",
    },
    html_content: {
    },
    contents_contents: {
      paddingLeft: 5,
    },

    container: {
      width: theme.breakpoints.xl
    },
    special_offer: {
      boxShadow: (theme.colorScheme !== "dark") ? "0 0 12px rgba(255, 255, 255, 0.25)" : "0 0 12px rgba(0, 0, 0, 0.25)",
    },
    footer_notes: {
      // [theme.fn.smallerThan("sm")]: {
      //   width: "50%"
      // }
    }
  }
});


export default function Plans() {
  const { classes } = useStyles();
  const preferredColorScheme = useColorScheme();
  const theme = useMantineTheme();
  const [loaded, setLoaded] = useState(false);

  const [columns_number, setColumnsNumber] = useState(0);
  const [period_txt_label, setPeriodTxtLabel] = useState("mese");
  const [period_txt_label_inverted, setPeriodTxtLabelInverted] = useState("anno");
  const [prices, setPrices] = useState(<Center><Space h={250} /><Loader /></Center>);
  const [price, setPrice] = useState("");
  const [frequency_payments, setMonthlyPayments] = useState("monthly");
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "colorScheme",
    defaultValue: preferredColorScheme,
  });
  const toggleColorScheme = (value?:ColorScheme) => setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const frequency_payments_inverted = (frequency_payments == "monthly") ? "yearly" : "monthly";

  interface Prices {
    size: number
    result: object
  }

  /**
   * Async GET prices data
   * @return {object}                                                   GET object data
   */
  async function getPrices() {
    try {
        const { data, status } = await axios.get(
          GetCurrentLocation() + "/prices/",
          {
            headers: {
              Accept: "application/json",
            },
          },
        );

        managePrices(data);
        setLoaded(true);
        return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message);
        return error.message;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  };

  function randomInt(min:number, max:number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  /**
   * Manage intercepted data and build the prices card
   * @param  {object}                   data                            Data provided by API GET
   */
  function managePrices(data:any) {
    if(data.size > 0) {
      const prices_obj = data.result.map((prices:any) => {
        setPeriodTxtLabelInverted((frequency_payments !== "monthly") ? "mese" : "anno");
        setPeriodTxtLabel((frequency_payments == "monthly") ? "mese" : "anno");
        if(prices.active) {
          const specialoffer = "special-offer" + randomInt(1, data.special_offer_size);
          return (
            <Card key={prices.type} className={(prices.special_offer ? classes.special_offer : "") + " no-print-border"} shadow="xl" p="lg" radius="md" sx={{overflow: "visible"}} withBorder>
              <Card.Section withBorder inheritPadding py="lg">
                <Group position="center">
                  <div>
                    <Text mb={-5} ta="center" tt="uppercase" c="dimmed" fz={15}>{prices.type}</Text>
                  </div>
                  {prices.special_offer
                    ? <Center ml={-20} sx={{zIndex:1}}>
                        <Stack align="flex-end" mt={-40} mr={-110}>
                          <Badge className={specialoffer} sx={{rotate: "21deg"}} color="yellow">SPECIAL OFFER</Badge>
                          <Container className={specialoffer} mt={-25} mb={-20} pr={60} sx={{rotate: "-15deg"}}>
                            <IconArrowUpRight size={40} color="teal" />
                          </Container>
                        </Stack>
                      </Center>
                    : <Text></Text>
                  }
                </Group>
              </Card.Section>
              <Card.Section withBorder inheritPadding py="lg">
                {prices.type !== "free"
                  ? <Group position="center" spacing={5}>
                      <Text ta="center" weight={300} fz={80} mb={-20}>{prices.price[frequency_payments]}</Text>
                      <Text mt={50} mr={-10} fz={20}>€</Text>
                        <Text mt={60} ml={10} fz={30}>/</Text>
                        <Text mt={60} ml={-5} fz={15}>{period_txt_label}</Text>
                    </Group>
                  : <Group position="center" spacing={5}>
                      <Text ta="center" color="dimmed" weight={300} fz={40} mt={40} mb={6} sx={{rotate: "-15deg"}}>GRATIS</Text>
                    </Group>
                }
                  <Center>
                  {prices.type !== "free"
                    ? <Text c="dimmed">Oppure <UnstyledButton
                                                component="a"
                                                onClick={() => {
                                                  setMonthlyPayments((frequency_payments == "monthly") ? "yearly" : "monthly")
                                                }}>
                                                {prices.price[frequency_payments_inverted] + "€/" + period_txt_label_inverted}
                                                </UnstyledButton></Text>
                    : <Text my={11} />
                  }
                  </Center>
              </Card.Section>

              <Stack justify="space-between" align="stretch" sx={{minHeight: 370}}>
                <Card.Section p="lg" mt={20}>
                  <List
                   key={prices.id}
                   spacing="lg"
                   size="lg"
                   sx={{padding: "0 !important", margin: "0 !important"}}
                   icon={<IconCircleCheck color="teal" size={20} />}
                   >
                    {prices.features.map((feature:string) => (
                      <List.Item key={feature} className="price" my={0}>
                        <Text size={16} my={0}><ReactMarkdown>{feature}</ReactMarkdown></Text>
                      </List.Item>
                    ))}
                  </List>
                </Card.Section>
                <Button className={prices.special_offer ? specialoffer : ""} variant="light" color={prices.colour} fullWidth mt={35} radius="md" onClick={(event) => {prices.button.target; event.preventDefault()}}>
                  {prices.button.text}
                </Button>
              </Stack>
            </Card>
          );
        }
      });
      if(columns_number !== data.size) {
        setColumnsNumber(data.size);
      }
      // console.log(data);
      setPrices(prices_obj);
    } else {
      setPrices(
        <div>
          <Center>
            <Image radius="lg" width={500} mr={150} src="no-way.png" withPlaceholder />
            <Stack align="center">
              <Text size={30}>Sorry, there's no data to display</Text>
              <Text size={30} c="dimmed">:(</Text>
            </Stack>
          </Center>
        </div>
        );
    }
  };

  useEffect(() => {
    getPrices();
  }, [price, frequency_payments, frequency_payments_inverted, period_txt_label, period_txt_label_inverted]);

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ colorScheme }}
      >
        <HeaderMenuDocs />
        <Divider variant="dotted" />
        <Group position="left" sx={{ alignItems: "flex-start !important" }}>
          <MediaQuery smallerThan="xl" styles={{ display: "none !important" }}>
            <div>
              <SidebarEmpty panel_openable={false} />
            </div>
          </MediaQuery>
          <TypographyStylesProvider
            id="contents"
            className={classes.contents}
            p={19}
          >
            <div id="pdf">
              <Breadcrumb />
              <div className={classes.contents_container}>
                <Group position="apart">
                  <div className={classes.contents_contents} dangerouslySetInnerHTML={{ __html: content }} />

                  <Group position="right" className="no-print">
                    <MediaQuery smallerThan="xs" styles={{ display: "none !important" }}>
                      <Text size="sm" c="dimmed">Tipo di abbonamento</Text>
                    </MediaQuery>
                    <SegmentedControl
                      mt={-2}
                      value={frequency_payments}
                      onChange={(value: "monthly" | "yearly") => {
                        setMonthlyPayments(value);
                        // getPrices();
                        // setPrices(<div>ok</div>);
                        // console.log("REFRESH!");

                      }}
                      data={[
                        {value: "monthly", label: (<Text pt={2}>Mensile</Text>)},
                        {value: "yearly", label: (<Text pt={2}>Annuale</Text>)}
                      ]}
                    />
                  </Group>
                </Group>
                <Group mt={40} mb={25} id="prices_container">
                  <Container id="prices" className={classes.container} size="xl" px="xs" my={0}>
                    <SimpleGrid
                      cols={columns_number}
                      spacing="xl"
                      breakpoints={
                        [
                          { maxWidth: "xs", cols: 1 },
                          { maxWidth: "sm", cols: 2 },
                          { maxWidth: "md", cols: 3 },
                          { maxWidth: "lg", cols: 4 },
                          // { maxWidth: "xl", cols: 4 },
                        ]
                      }
                    >
                    {prices}
                    </SimpleGrid>
                    <Center c="dimmed" fz="xs" mt={30} mb={-30}>
                      {loaded && columns_number != 0
                        ? <div>
                            <Text sx={{display: "inline-block"}} className={classes.footer_notes}>Termini e condizioni applicabili.</Text> <Text sx={{display: "inline-block"}}>Puoi annullare il rinnovo dell'abbonamento quando vuoi.</Text>
                          </div>
                        : <Text></Text>
                      }
                    </Center>
                  </Container>
                </Group>
              </div>
            </div>
          </TypographyStylesProvider>
        </Group>
        <Footer />
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
