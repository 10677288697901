import React, { useState } from "react";
import {
  createStyles,
  Center,
  ColorScheme,
  ColorSchemeProvider,
  Divider,
  Group,
  Loader,
  MantineProvider,
  Space,
  TypographyStylesProvider,
  useMantineColorScheme,
} from "@mantine/core";
import { useColorScheme, useLocalStorage } from "@mantine/hooks";
import axios from "axios";

import { GetCurrentLocation } from "../_utilities/common";
import { HeaderMenuDocs } from "../HeaderMenuDocs/HeaderMenuDocs";
import { Breadcrumb } from "../Breadcrumb/Breadcrumb";
import { LeftSidebar } from "../Sidebar/LeftSidebar";
import { RightSidebar } from "../Sidebar/RightSidebar";
import { Footer } from "../Footer/Footer";

const useStyles = createStyles((theme) => ({
  contents_container: {
    paddingLeft: 20,
    width: "100%",
  },
  contents: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    position: "static",
    boxSizing: "border-box",
    maxWidth: "calc(100% - 225px)",
    width: "100%"
  },
  html_content: {
  },
}));

export function Community() {
  const { classes, theme } = useStyles();
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "colorScheme",
    defaultValue: preferredColorScheme,
  });
  const toggleColorScheme = (value?:ColorScheme) => setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  // ---
  const [loaded, setLoaded] = useState(false);
  const [content, setContent] = useState(<Center><Space h={250} /><Loader /></Center>);

  async function getContents() {
    try {
      const { data, status } = await axios.get(
        GetCurrentLocation() + "/contents/community",
        {
          headers: {
            Accept: "application/json",
          },
        },
      );

      setLoaded(true);
      setContent(<div className={classes.html_content} dangerouslySetInnerHTML={{ __html: data.content }} />);
      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message);
        return error.message;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  };
  if(!loaded) {
    getContents();
  }

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ colorScheme }}
      >
        <HeaderMenuDocs />
        <Divider variant="dotted" />
        <Group position="left" sx={{ alignItems: "flex-start !important" }}>
          <LeftSidebar />
          <TypographyStylesProvider
            id="contents"
            className={classes.contents}
            ml={-16}
            p={19}
          >
            <div id="pdf">
              <Breadcrumb />
              <div className={classes.contents_container}>
                {content}
              </div>
            </div>
          </TypographyStylesProvider>
          <RightSidebar />
        </Group>
        <Footer />
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
