import { useWindowScroll } from "@mantine/hooks";
import {
  Affix,
  Button,
  Transition,
} from "@mantine/core";
import { IconArrowNarrowUp } from "@tabler/icons";

export function GoToTop() {
  const [scroll, scrollTo] = useWindowScroll();
  return (
    <div>
      <Affix id="gototop" position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              color="gray"
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
              size="md"
            >
              <IconArrowNarrowUp size={18}  />
            </Button>
          )}
        </Transition>
      </Affix>
    </div>
  );
}
