import { useMantineColorScheme, ActionIcon, Tooltip } from "@mantine/core";
import { IconSun, IconMoon } from "@tabler/icons";
// import { reactLocalStorage } from "reactjs-localstorage";

export function ColorSchemeToggle() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  // toggleColorScheme(reactLocalStorage.get("colorScheme"))
  const dark = colorScheme === "dark";

  // onClick={() => {toggleColorScheme(); reactLocalStorage.set("colorScheme", colorScheme)}}
  return (
    <Tooltip
      label="Cambia colore del tema"
      color="gray"
      position="bottom-end"
      withArrow
    >
      <ActionIcon
        variant="outline"
        color={dark ? "yellow" : "blue"}
        onClick={() => toggleColorScheme()}
        mr={-5}
        size={34}
      >
        {dark ? <IconSun size={16} stroke={1.5} /> : <IconMoon size={16} stroke={1.5} />}
      </ActionIcon>
    </Tooltip>
  );
}
