/**
 * Calculate the day Moon phase
 * @see https://gist.github.com/datatypevoid/f4dd1f6439feaa588bb2aaf4f8f4361f
 *
 * @see Weather Icons (Github) <https://github.com/erikflowers/weather-icons>
 * @see Weather Icons (List) <https://erikflowers.github.io/weather-icons>
 * @see Moon Phase for today <https://www.moongiant.com/phase/today/>
 *
 * HOW TO USE
 * Install the weath`weather-icons` package and then in the function file, import this script
 * @example
 * `$ npm i weather-icons --save`
 *
 * and then in the script:
 * ```
 * import { getMoonPhase } from "../getMoonPhase/getMoonPhase";
 * import "weather-icons/css/weather-icons.min.css"
 * ```
 */

import { GetLanguage, SetLanguage, } from "../_utilities/languages";

export const MOON_ICONS: [
  New: string,
  WaxingCrescent: string,
  QuarterMoon: string,
  WaxingGibbous: string,
  Full: string,
  WaningGibbous: string,
  LastQuarter: string,
  WaningCrescent: string
] = ["wi-moon-new", "wi-moon-waxing-crescent-4", "wi-moon-waning-gibbous-1", "wi-moon-waxing-gibbous-5", "wi-moon-full", "wi-moon-waning-gibbous-5", "wi-moon-waning-crescent-1", "wi-moon-waning-crescent-3"];

const language = GetLanguage();
const language_id = language.id;
// const links = menu[language_id as keyof typeof menu];
// console.log(language_id);

if(language_id == "english") {
  var MOON_PHASE_NAMES = [
    "New",
    "Waxing Crescent",
    "Quarter Moon",
    "Waxing Gibbous",
    "Full",
    "Waning Gibbous",
    "Last Quarter",
    "Waning Crescent",
  ];
} else if(language_id == "italiano") {
  var MOON_PHASE_NAMES = [
    "Nuova",
    "Crescente",
    "Quarto di luna",
    "Gibbosa crescente",
    "Piena",
    "Gibbosa calante",
    "Ultimo quarto",
    "Calante",
  ];
}
// export const MOON_PHASE_NAMES;

/**
 * Lunar cycle days length
 * An average synodic month takes 29 days, 12 hours, 44 minutes, 3 seconds.
 * Reference: http://individual.utoronto.ca/kalendis/lunar/#FALC - Also known as a synodic month.
 */
export const LUNAR_CYCLE = 29.5305882; // 29.53058770576
/**
 * Earth cycle days length
 */
export const DAYS_PER_YEAR = 365.25;
/**
 * Days per month
 */
export const DAYS_PER_MONTH = 30.6;

/**
 * The number of days between `0 AD` and the new moon on `1900-01-01`.
 */
export const NEW_MOON_REFERENCE = 694039.09;

// Ported from `http://www.voidware.com/moon_phase.htm`.
export const getMoonPhase = (date: Date = new Date()) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  // Adjustments
  // ----------------
  if (month < 3) {
    year -= 1;
    month += 12;
  }
  month += 1;
  // day += 1;
  // ----------------

  /**
   * Count days from the year "0 AD"
   * @return number                   daysSinceZeroAd                           The count of days from the year "0 AD"
   */
  const daysSinceZeroAd = DAYS_PER_YEAR * year + DAYS_PER_MONTH * month + day;
  /**
   * The number of days between "0 AD" and the new moon on "1900-01-01"
   * @return number                   daysSinceNewMoonReference                 The number of days between "0 AD" and the new moon on "1900-01-01"
   */
  const daysSinceNewMoonReference = daysSinceZeroAd - NEW_MOON_REFERENCE;
  /**
   * The number of lunar cycles between "0 AD" and the new moon on "1900-01-01"
   * @return number                   numberOfCyclesSinceReference              The number of lunar cycles between "0 AD" and the new moon on "1900-01-01"
   */
  const numberOfCyclesSinceReference = daysSinceNewMoonReference / LUNAR_CYCLE;
  /**
   * The percentage of lunar cycles between "0 AD" and the new moon on "1900-01-01"
   * @return number                   percentageOfCycleComplete                 The percentage of lunar cycles between "0 AD" and the new moon on "1900-01-01"
   */
  const percentageOfCycleComplete = numberOfCyclesSinceReference - Math.trunc(numberOfCyclesSinceReference);

  // Scale fraction from 0 to 8
  let phase = Math.round(percentageOfCycleComplete * 8);
  if (phase >= 8) phase = 0; // `0` and `8` are the same so turn `8` into `0`.

  // Scale fraction from 0 to `LUNAR_CYCLE`
  const lunarDay = Math.round(percentageOfCycleComplete * LUNAR_CYCLE);

  if (!MOON_PHASE_NAMES[phase]) throw new Error(`Invalid moon phase: ${phase}`);

  return {
    lunar_day: lunarDay,
    name: MOON_PHASE_NAMES[phase],
    icon: "wi " + MOON_ICONS[phase]
  }
};
