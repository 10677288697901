
// import {reactLocalStorage} from "reactjs-localstorage";

export function GetCurrentLocation() {
  console.info("LOCATION", window.location);
  var location = "";
  if(window.location.host == "localhost:3000") {
    location = window.location.protocol + "//api.mapsource.local";
    console.log("LOCAL", location);
  } else {
    location = window.location.protocol + "//" + window.location.host.replace("v2", "api");
    console.log("ONLINE", location);
  }
  return location;
}

// export function SetLanguage(language:string) {
//   const language_object = {
//     id: language.toLowerCase(),
//     label: language.charAt(0).toUpperCase(),
//     image: "langs/" + + language.toLowerCase() + ".png"
//   };
//   reactLocalStorage.set("language", JSON.stringify(language_object));
// }
