import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route }	from "react-router-dom";
import { Project } from "./components/_pages/Project";
import { FAQ } from "./components/_pages/FAQ";
import { Manifesto } from "./components/_pages/Manifesto";
import { Regulations } from "./components/_pages/Regulations";
import { Community } from "./components/_pages/Community";
import { Contribute } from "./components/_pages/Contribute";
import Plans from "./components/_pages/Plans";
import { Contacts } from "./components/_pages/Contacts";
import { Login } from "./components/_pages/Login";
import Main from "./components/_pages/Index";

function App() {
	return (
		<Router>
			<Routes>
			<Route path="/" element={<Main/>}/>
				<Route path="/doc/Project" element={<Project />}/>
				<Route path="/doc/Project/FAQ" element={<FAQ />}/>
				<Route path="/doc/Project/Manifesto" element={<Manifesto />}/>
				<Route path="/doc/Project/Rules-and-regulations" element={<Regulations />}/>

				<Route path="/doc/Community" element={<Community />}/>
				<Route path="/doc/Community/Contribute" element={<Contribute />}/>

				<Route path="/doc/Plans" element={<Plans />}/>

				<Route path="/doc/Contacts" element={<Contacts />}/>
				<Route path="/doc/Login" element={<Login />}/>
			</Routes>
		</Router>
	);
}

export default App;
