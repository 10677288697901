import React, { useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import {
  createStyles,
  Accordion,
  Autocomplete,
  Badge,
  Center,
  ColorScheme,
  ColorSchemeProvider,
  Divider,
  Group,
  Loader,
  MantineProvider,
  MediaQuery,
  Space,
  Stack,
  Text,
  TypographyStylesProvider,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import {
  useColorScheme,
  useFocusTrap,
  useLocalStorage,
  useScrollIntoView,
  useWindowScroll,
} from "@mantine/hooks";
import { IconQuestionCircle, IconFileSearch } from "@tabler/icons";
import axios from "axios";

import { GetCurrentLocation } from "../_utilities/common";
import { HeaderMenuDocs } from "../HeaderMenuDocs/HeaderMenuDocs";
import { Breadcrumb } from "../Breadcrumb/Breadcrumb";
import { LeftSidebar } from "../Sidebar/LeftSidebar";
import { RightSidebar } from "../Sidebar/RightSidebar";
import { Footer } from "../Footer/Footer";

const content = "<h3>Domande frequenti</h3>";

const useStyles = createStyles((theme) => ({
  contents_container: {
    // margin: 0,
    // display: "flex",
    // flexDirection: "column",
    // position: "static",
    // boxSizing: "border-box",
    width: "100%",
  },
  contents: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    position: "static",
    boxSizing: "border-box",
    width: "75%",
  },
  html_content: {
  },
  filterContainer: {
    width: "75%",
  },
  filterSearchInput: {
    width: "100%",
  },
}));

export function FAQ() {
  const { classes } = useStyles();
  const [scroll, scrollTo] = useWindowScroll();
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ offset: 60 });
  const preferredColorScheme = useColorScheme();
  const theme = useMantineTheme();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "colorScheme",
    defaultValue: preferredColorScheme,
  });
  const panel_opened = eval(reactLocalStorage.get("panel_opened"));
  const toggleColorScheme = (value?:ColorScheme) => setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const getColor = (color:string) => theme.colors[color][theme.colorScheme === "dark" ? 5 : 7];
  const focusTrapRef = useFocusTrap();
  // ---
  const [loaded, setLoaded] = useState(false);
  const [content, setContent] = useState(<Center><Space h={250} /><Loader /></Center>);
  const [default_opened, setDefaultOpened] = useState("");

  const [keys, setKeys] = useState<string[]>([]);
  type Blob = {
    key: string;
    label: string;
    value: string;
    default_opened: boolean;
    context: string;
    colour: string;
    description: string;
    content: string;
  }
  const [blob, setBlob] = useState<Blob[]>([]);
  const [accordionValue, setAccordion] = useState("");
  const [autocomplete, setAutocomplete] = useState("");

  interface AccordionLabelProps {
    label: string;
    context: string;
    description: string;
    colour: string;
  }
  function AccordionLabel({label, context, description, colour}:AccordionLabelProps) {
    return (
      <Group position="apart">
        <div>
          <Text fs="italic">{label}</Text>
          <Text size="sm" color="dimmed" weight={400}>
            {description}
          </Text>
        </div>
        <MediaQuery smallerThan="xs" styles={{ display: "none !important" }}>
          <Badge color={colour} variant="outline">{context}</Badge>
        </MediaQuery>
      </Group>
    );
  }
  interface QuestionProps {
    questions: any;
    default_panel_opened: string;
  }
  function Accordions({questions, default_panel_opened}:QuestionProps) {
    const items = questions.map((item:any) => {
      return (
        <Accordion.Item value={item.item_id} key={item.key} id={item.item_id} sx={{width: "100%", padding: 0}}>
          <Accordion.Control icon={<IconQuestionCircle size={20} color={getColor(item.colour)} />} className="no-print-padding">
            <AccordionLabel {...item} />
          </Accordion.Control>
          <Accordion.Panel className="display-accordion-on-print" pl={5} py={20} mb={50}>
            {item.content}
          </Accordion.Panel>
        </Accordion.Item>
      )
    });
    return <Accordion mt={30} className={classes.contents + " width-100"} variant="contained" defaultValue={(default_opened) ? default_opened : accordionValue}>{items}</Accordion>
  }

  async function getContents() {
    try {
      const { data, status } = await axios.get(
        GetCurrentLocation() + "/faq/",
        {
          headers: {
            Accept: "application/json",
          },
        },
      );
      const key_set = new Set<string>();
      const blob_set = new Set<Blob>();

      const questions = data.result.map((question:any) => {
        const key:string = question.item_id;

        if(keys.length == 0 && !keys.includes(key)) {
          key_set.add(key);
          blob_set.add({
            key: key,
            label: question.label,
            value: question.label,
            default_opened: question.default_opened,
            context: question.context,
            colour: question.colour,
            description: question.description,
            content: question.content
          });

          setKeys([...key_set.values()]);
          setBlob([...blob_set.values()]);
        // } else {
        //   // Clean keys duplicates
        //   if(blob.length > keys.length) {
        //     setBlob(blob.filter(a => a.key !== key));
        //   }
        }
        return {
          key: key,
          item_id: question.item_id,
          default_opened: question.default_opened,
          label: question.label,
          context: question.context,
          colour: question.colour,
          description: question.description,
          content: (
            <Text>{question.content}</Text>
          )
        }
      });
      // console.warn("KEYS", keys);
      // console.log("BLOB", blob);

      setContent(
        <div>
          <Stack>
            <Text className="no-print-padding" component="h3" sx={{paddingLeft: "calc(25% / 2)", marginBottom: "50px !important"}}>Domande frequenti</Text>
            <Stack align="center" className="print-align-start no-print-border">
              <div id="search_faq_input" className={classes.filterContainer}>
                <Autocomplete
                  size="xl"
                  className={classes.filterSearchInput}
                  label="Filtra la ricerca"
                  placeholder="Digita del testo per trovare velocemente la risposta"
                  icon={<IconFileSearch size={26} />}
                  initiallyOpened={false}
                  data={blob}
                  defaultValue={autocomplete}
                  onChange={(data) => {
                  }}
                  onItemSubmit={(data) => {
                    blob.map((v:any) => {
                      if(v.label === data.label) {
                        setAccordion(v.key);
                        // setAutocomplete("");
                        document.getElementById(v.key).scrollIntoView({ behavior: 'smooth', block: 'start' });
                      }
                    })
                    // console.log("ITEM", data);
                  }}
                />
              </div>
              <Divider
                orientation="horizontal"
                size="xs"
                variant="dashed"
                sx={{width: "75% !important"}}
                mt={50}
                mb={30}
                label="oppure cerca tra le domande"
                labelPosition="center"
                className="no-print"
              />
              <Accordions questions={questions} default_panel_opened={data.default_panel_opened} />
            </Stack>
          </Stack>
        </div>
      );
      setDefaultOpened(data.default_opened);
      setLoaded(true);

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message);
        return error.message;
      } else {
        console.log('unexpected error: ', error);
        return 'An unexpected error occurred';
      }
    }
  };
  React.useEffect(() => {
    getContents();
  }, [loaded, accordionValue, default_opened]);


  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ colorScheme }}
      >
        <HeaderMenuDocs />
        <Divider variant="dotted" />
        <Group position="left" sx={{ alignItems: "flex-start !important" }}>
          <LeftSidebar />
            <TypographyStylesProvider
              id="contents"
              // className={classes.contents}
              // ml={-16}
              py={19}
              mb={30}
            >
              <div id="pdf">
                <Breadcrumb />
                <div className={classes.contents_container}>
                  {content}
                </div>
              </div>
            </TypographyStylesProvider>
            <RightSidebar />
          </Group>
        <Footer />
      </MantineProvider>
  </ColorSchemeProvider>
  );
}
