import { useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  createStyles,
  Divider,
  Group,
  MediaQuery,
  Navbar,
  Text,
  Tooltip,
  useMantineColorScheme
} from "@mantine/core";
import {
  IconChevronRight,
  IconFileText,
  IconNotebook,
  IconPrinter,
  IconVocabulary,
  IconBellRinging,
  IconReceipt2,
  IconFingerprint,
  IconKey,
  IconDatabaseImport,
  Icon2fa,
  IconSettings,
} from "@tabler/icons";
import $ from "jquery";
import {
  GetLanguage,
} from "../_utilities/languages";
import menu from "../../data/json/menu.json";

const language = GetLanguage();
const language_id = language.id;
const local_menu = menu[language_id as keyof typeof menu];

const useStyles = createStyles((theme) => ({
  navbar: {
    background: theme.colorScheme === "dark" ? "unset" : "linear-gradient(to right, #eee, #fafafa 8px)",
    boxShadow: theme.colorScheme === "dark" ? "0 0 100px #111 inset" : "0 0 100px #fff inset",
    zIndex: 0,
  },

  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: theme.spacing.md * 1.5,
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  // panelIcon: {
  //   transition: "transform 150ms ease",
  //   transform: (opened) ? "rotate(-180deg)" : "rotate(0deg)",
  // },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,

      // [`& .${icon}`]: {
      //   color: theme.colorScheme === "dark" ? theme.white : theme.black,
      // },
    },
  },

  linkIcon: {
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor }).color,
      // [`& .${icon}`]: {
      //   color: theme.fn.variant({ variant: "light", color: theme.primaryColor }).color,
      // },
    },
  },
}));

const related_pages:string = "Pagine correlate";
const mapsource_address:string = window.location.pathname;

export function RightSidebar() {
  const { classes, cx } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  var right_sidebar_visible = false;

  const items = local_menu.map((group) => {
    if(group.links) {
      if(group.links.some(e => e.link.includes(mapsource_address))) {
        right_sidebar_visible = true;

        const links = group.links.map((link, index) => {
          return (
            <Text<"a">
              key={link.label}
              className={classes.link}
              component="a"
              href={link.link}
              onClick={(event) => {
                if(link.active == "false") {
                  event.preventDefault();
                }
              }}
              px={0}
            >
              <IconChevronRight size={10} stroke={1.5} />
              <Text ml={5} color="gray.7">{link.label}</Text>
            </Text>
          )
        });

        return (
          <div>
            {links}
          </div>
        );
      } else {
        return (<></>);
      }
    } else {
      return (<></>);
    }
  });

  if(right_sidebar_visible) {
    return (
      <MediaQuery smallerThan="md" styles={{ display: "none !important" }}>
        <Group ml={-10}>
          <Divider
            orientation="vertical"
            mr={-16}
            mb={-1}
            w={1}
            sx={{
              border: "0px none",
              background: colorScheme === "dark" ? "linear-gradient(to bottom, #222 50%, #333)" : "linear-gradient(to bottom, #efefef 50%, #dedede)"
            }} />
          <Navbar id="right_sidebar" w={200} px={20} py={15} className={classes.navbar}>
            <Navbar.Section grow>
              <Group mb={10} className={classes.header} position="apart">
                <Text c="dimmed" mt={2} mb={-2} component="span">{related_pages}</Text>
              </Group>
              {items}
            </Navbar.Section>
          </Navbar>
        </Group>
      </MediaQuery>
    );
  } else {
    return (<></>);
  }
}
