
import {reactLocalStorage} from "reactjs-localstorage";

export function GetLanguage() {
  const language = reactLocalStorage.get("language");
  if(!language) {
    SetLanguage("english");
  }
  // {"id":"italiano","label":"Italiano","image":"langs/italian.png"}
  return JSON.parse(reactLocalStorage.get("language"));
}

export function SetLanguage(language:string) {
  const language_object = {
    id: language.toLowerCase(),
    label: language.charAt(0).toUpperCase(),
    image: "langs/" + + language.toLowerCase() + ".png"
  };
  reactLocalStorage.set("language", JSON.stringify(language_object));
}
