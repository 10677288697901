import {
  createStyles,
  Anchor,
  Breadcrumbs,
  Divider,
} from "@mantine/core";
import { GetLanguage, SetLanguage, } from "../_utilities/languages";

import menu from "../../data/json/menu.json";
const language = GetLanguage();
const language_id = language.id;
const links = menu[language_id as keyof typeof menu];

const mapsource_address:string = window.location.pathname;
const bread:any = [];

const useStyles = createStyles((theme) => ({
  breadcrumb: {
    // paddingLeft: 20,
  },
}));
const items = links.map((link) => {
  if(link.links) {
    if(link.link == "." + mapsource_address) {
      bread.push({title: link.label, href: link.link});
    } else {
      link.links?.forEach(submenu => {
        if(submenu.link == "." + mapsource_address) {
          bread.push({title: link.label, href: link.link});
          bread.push({title: submenu.label, href: submenu.link});
        }
      });
    }
  } else {
    if(link.link == "." + mapsource_address) {
      bread.push({title: link.label, href: link.link});
    }
  }
});

const breadcrumb:any = bread.map((item:any, index:any) => (
  <Anchor href={item.href} key={index}>
    {item.title}
  </Anchor>
));

export function Breadcrumb() {
  const { classes } = useStyles();

  return (
    <div id="breadcrumb" className={classes.breadcrumb}>
      <Breadcrumbs mb={10}>{breadcrumb}</Breadcrumbs>
      <Divider mb={40} variant="dotted" />
    </div>
  );
}
