import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import {
  createStyles,
  Menu,
  Center,
  Header,
  Container,
  Group,
  Button,
  Image,
  Burger,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons";
import { LanguagePicker } from "../LanguagePicker/LanguagePicker";
import { GetLanguage, SetLanguage, } from "../_utilities/languages";

import layer_type from "../../data/json/map_layers.json";
import menu from "../../data/json/menu.json";

const language = GetLanguage();
const language_id = language.id;
const links = menu[language_id as keyof typeof menu];

const MENU_HEIGHT = 69;
const LOGO_WIDTH = (MENU_HEIGHT * 1.5);
const LOGO_HEIGHT = (MENU_HEIGHT * 1.5);
const PADDING_TOP = (MENU_HEIGHT/1.5-1);

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  inner: {
    height: MENU_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "top",
  },

  header: {
    alignItems: "end",
  },

  container: {
    maxWidth: "unset",
  },

  logo_img: {
    top: 0,
    position: "absolute",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    paddingTop: PADDING_TOP,
    paddingRight: "10px",
    paddingBottom: "10px",
    paddingLeft: "35px",
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    flexWrap: "wrap",

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: 5,
  },
  sublink: {
    display: "block",
    lineHeight: 1,
    padding: "10px 10px",
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

export function HeaderMenu() {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });

  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <a
        key={item.label}
        href={item.link}
        className={classes.sublink}
      >
        {item.label}
      </a>
    ));

    if (menuItems) {
      return (
        <Menu
          id="menu"
          exitTransitionDuration={0}
          onClose={() => setOpened(false)}
          onOpen={() => setOpened(true)}
          position="bottom-end"
          trigger="hover"
          width="target"
          key={link.label}
        >
          <Menu.Target>
            <a
              key={link.label}
              href={link.link}
              className={classes.link + " submenu"}
              onClick={(event) => (link.link == "#") ? event.preventDefault() : false}
            >
              <Center>
                <span className={classes.linkLabel}>{link.label}</span>
                <IconChevronDown size={12} className={classes.icon} stroke={1.5} />
              </Center>
            </a>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <a
        key={link.label}
        href={link.link}
        className={classes.link}
        onClick={(event) => (link.link == "#") ? event.preventDefault() : false}
      >
        {link.label}
      </a>
    );
  });
  return (
    <Header height={90} className={classes.header}>
      <Container className={classes.container} p={0}>
        <div className={classes.inner}>
          <a href="" className="logo_link" id="logo">
            <Image
              width={LOGO_WIDTH}
              height={LOGO_HEIGHT}
              ml={10}
              src="logo_negative.png"
            />
          </a>
          <Group id="menu" spacing={5} className={classes.links}>
            {items}
            <LanguagePicker />
          </Group>
        </div>
      </Container>
    </Header>
  );
}
